.discount {
  margin-bottom: 70px;

  &_items {
    display: flex;
    flex-wrap: wrap;
    margin: 20px -10px -20px;
    @include sm {
      margin: 0 0 -20px;
    }
  }

  &_items &_item {
    width: calc(50% - 62px);
    margin: 0 10px 20px;
    @include sm {
      width: 100%;
      margin: 0 0 20px;
    }
  }

  /*
      ELEMENT item
  */
  &_item {
    background: url("../images/ic_adv_4.png") calc(100% - 20px) 20px/ 45px no-repeat;
    padding: 20px;
    border: 1px solid #dfe2e5;
    border-radius: 8px;

    @include sm {
      background: none;
      border-radius: 0;
      padding: 0 0 20px;
      border: 0;
      border-bottom: 1px solid #dfe2e5;
    }

    &-name {
      display: block;
      margin-bottom: 20px;
      @include Panton(600);
      word-break: break-all;
      line-height: 22px;
      font-size: 20px;
      padding-right: 50px;
      color: #2fb7d3;
    }

    &-title {
      color: #23282d;
      @include Panton(600);
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 22px;
    }

    &-text {
      @include Panton(500);
      line-height: 22px;
      margin-bottom: 20px;
      font-size: 16px;
    }

    &-date {
      background: url("../images/ic_date.png") 0 2px no-repeat;
      color: #8e9499;
      padding-left: 30px;
      line-height: 26px;
    }

  }

}