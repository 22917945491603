.centers {
    margin-bottom: 50px;
    .cont {
        @include clear;
        @include sm {
            padding: 0;
        }
    }
    .more_container {
        .pagination {
            display: none !important;
        }
        .more_item {
            display: block !important;
        }
    }
    .list {
        .center_item:last-child {
            margin-bottom: 0;
        }
    }
    .center_item {
        margin-bottom: 15px;
        box-shadow: 0 1px 12px $grey_shadow;
        border-radius: 20px;
        background-color: $white;
        padding: 20px;
        overflow: hidden;
        @include sm {
            border-radius: 0;
            padding: 15px 40px;
        }
        @include smm {
            padding: 15px 20px;
        }
        .top {
            display: flex;
            margin-bottom: 20px;
            @include sm {
                margin-bottom: 10px;
            }
            .col:first-child {
                margin-right: 15px;
            }
            .col:last-child {
                width: 100%;
            }
            .logo {
                display: block;
                border-radius: 20px;
                border: 1px solid $grey_border_logo;
                background-color: $white;
                padding: 15px 0;
                margin-bottom: 10px;
                max-width: 110px;
                max-height: 61px;
                overflow: hidden;
                @include sm {
                    padding: 10px 0;
                    margin: 0;
                }
                img {
                    @include sm {
                        max-width: 70px;
                        max-height: 48px;
                    }
                }
            }
            .title {
                margin: 10px 0;
                display: block;
                color: $cyan_link;
                @include Panton(400);
                font-size: 18px;
                text-transform: uppercase;
            }
            .rating {
                @include sm {
                    display: flex;
                    align-items: center;
                    .stars {
                        margin-right: 10px;
                    }
                }
            }
        }
        .price_list {
            color: $grey_text_mid;
            @include Panton(300);
            font-size: 16px;
            @include sm {
                margin-bottom: 10px;
                font-size: 13px;
            }
            li {
                position: relative;
                @include clear;
                @include sm {
                    margin-bottom: 10px;
                }
                &:hover {
                    a,
                    span:not(.old_price) {
                        color: $cyan_link;
                    }
                }
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 8px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    border-bottom: 1px dotted $grey_text_mid;
                }
                &:last-child {
                    &:before {
                        bottom: 5px;
                    }
                    @include sm {
                        .right {
                            width: 100%;
                            float: none;
                            padding: 0;
                            text-align: left;
                        }
                    }
                }
            }
            .left {
                padding-right: 5px;
                @include sm {
                    width: calc(60% - 5px);
                }
            }
            .right {
                padding-left: 5px;
                @include sm {
                    width: calc(40% - 5px);
                    text-align: right;
                }
            }
            .left,
            .right {
                background-color: $white;
                position: relative;
                z-index: 1;
                box-sizing: border-box;
            }
            .name {
                color: $black;
                @include Panton(400);
                line-height: 25px;
                @include sm {
                    line-height: 15px;
                }
            }
            .price {
                color: $black;
                @include Panton(500);
                line-height: 25px;
            }
            .old_price {
                color: $grey_text_dark;
                @include OpenSans(400);
                font-size: 12px;
                text-decoration: line-through;
                margin-right: 5px;
            }
            .right a {
                color: $cyan_link;
                @include Panton(500);
                font-size: 15px;
            }
        }
        .address {
            border-radius: 20px;
            background-color: $grey_address_bg;
            @include sm {
                background: none;
                margin: 0 -40px;
                padding: 15px 40px 0;
                border-top: 1px solid $grey_btn_border;
                border-radius: 0;
            }
            .item {
                display: inline-block;
                margin: 10px 5px 10px 15px;
                color: $grey_address_text;
                @include OpenSans(400);
                font-size: 13px;
                line-height: 22px;
                text-decoration: none;
                @include sm {
                    margin: 0;
                    &:not(:first-child) {
                        display: none;
                    }
                }

            }
            a.item {
                color: $cyan_link;
            }
            .street,
            .metro {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    background-color: initial;
                    background-image: url("../images/ic_department.webp"); /* Firefox */
                    background-image: -webkit-image-set(
                            url("../images/ic_department.webp") 1x
                    );
                    background-image: image-set(
                            url("../images/ic_department.png") 1x
                    ); /* Safari */
                    width: 14px;
                    height: 16px;
                    left: 0;
                    top: 2px;
                }
            }
            .street {
                padding-left: 21px;
            }
            .metro {
                padding-left: 26px;
                &:before {
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    background-color: initial;
                    background-image: url("../images/ic_metro.webp"); /* Firefox */
                    background-image: -webkit-image-set(
                            url("../images/ic_metro.webp") 1x
                    );
                    background-image: image-set(
                            url("../images/ic_metro.png") 1x
                    ); /* Safari */
                    width: 21px;
                    height: 15px;
                    top: 2px;
                }
                &:hover {
                    color: $purple_bg;
                }
                &.active {
                    color: $purple_bg;
                    @include OpenSans(500);

                    &:before {
                        background-image: url("../images/ic_metro_a.webp"); /* Firefox */
                        background-image: -webkit-image-set(
                                url("../images/ic_metro_a.webp") 1x
                        );
                        background-image: image-set(
                                url("../images/ic_metro_a.png") 1x
                        ); /* Safari */
                    }
                }
            }

            & + .stickers {
                margin-top: 10px;
            }
        }
        .stickers {
            display: none;
            .item {
                display: none;
                border-radius: 15px;
                border: 1px solid #f1f2f7;
                background-color: $cyan_link;
                color: $white;
                padding: 7px 10px;
                @include OpenSans(400);
                font-size: 14px;
                line-height: 14px;
                margin-right: 5px;
            }
        }
        .bottom {
            display: flex;
            margin-top: 20px;
            justify-content: space-between;
            .total {
                background-repeat: no-repeat;
                background-position: 0 0;
                background-color: initial;
                background-image: url("../images/ic_notify.webp"); /* Firefox */
                background-image: -webkit-image-set(
                        url("../images/ic_notify.webp") 1x
                );
                background-image: image-set(
                        url("../images/ic_notify.png") 1x
                ); /* Safari */
                padding-left: 32px;
                @include Panton(500);
                color: $grey_total_text;
                font-size: 13px;
                line-height: 14px;
                b {
                    color: $cyan_link;
                    font-size: 15px;
                }
                @include sm {
                    display: none;
                }
            }
            .phone {
                background-repeat: no-repeat;
                background-position: 0 0;
                background-color: initial;
                background-image: url("../images/ic_phone_purple.webp"); /* Firefox */
                background-image: -webkit-image-set(
                        url("../images/ic_phone_purple.webp") 1x
                );
                background-image: image-set(
                        url("../images/ic_phone_purple.png") 1x
                ); /* Safari */
                padding-left: 32px;
                &.not_partner {
                    @include Panton(400);
                    display: flex;
                    align-items: center;
                    max-width: 220px;
                    @include sm {
                        max-width: inherit;
                        font-size: 13px;
                        line-height: 18px;
                    }
                    a {
                        margin-top: 0;
                        font-size: 14px;
                        color: $grey_text_dark;
                    }
                }
                a {
                    display: block;
                    margin-top: 5px;
                    color: $purple_bg;
                    @include Panton(800);
                    font-size: 20px;
                    @include sm {
                        @include link;
                        margin: 0;
                        width: 100%;
                        text-align: center;
                        font-size: 18px;
                        @include Panton(500);
                    }
                }
                @include sm {
                    width: 100%;
                    display: block;
                    background: none;
                    padding: 0;
                }
            }
        }
    }
    .buttons {
        position: sticky;
        bottom: 20px;
        z-index: 1;
        width: 100%;
        @include sm {
            margin-top: 20px;
        }
        .cont {
            display: flex;
            justify-content: flex-end;
            @include sm {
                padding: 0 40px;
            }
        }
        .map_open & {
            z-index: 6;
            position: fixed;
            .centers_on_map {
                margin: 0;
                width: 100%;
                text-align: center;
                & + div {
                    display: none;
                }
            }
        }
        .centers_on_map {
            @include link;
            margin-top: 0;
            background-color: $cyan_link;
            color: $white;
            margin-right: 20px;
            display: none;
            @include sm {
                display: block;
                padding: 10px 15px;
                width: 100%;
                text-align: center;
            }
        }
        .to_top {
            position: relative;
            top: -45px;
            right: -25px;
            @include sm {
                display: block;
                top: auto;
                right: auto;
            }
        }
    }
}

.centers {
    .hours24 {
        .stickers {
            display: block;
            .hours24 {
                display: inline-block;
            }
        }
    }
    .det {
        .stickers {
            display: block;
            .det {
                display: inline-block;
            }
        }
    }
    .akcii {
        .stickers {
            display: block;
            .akcii {
                display: inline-block;
            }
        }
    }
    .skontrastom {
        .stickers {
            display: block;
            .skontrastom {
                display: inline-block;
            }
        }
    }
}