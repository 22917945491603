.main-block {
  background: $block_bg;
  border-radius: 20px;
  padding: 40px;
  margin-bottom: 30px;

  .attention {
    background: white;
    border: 2px dashed #d3dcff;
    border-radius: 20px;
    padding: 14px 20px;
  }

  @include sm {
    margin: 0 -24px 30px;
    img {
      width: 100%;
    }
  }

  h2, h3 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;

    &:not(:first-child) {
      margin-top: 30px;
    }

    @include sm {

    }
  }

  h3 {
    font-size: 20px;
  }

  p {
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
    line-height: 1.4em;
  }

  table {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #16276a;

    @media only screen and (max-width: "500px") {
      font-size: 3vw;
    }
  }

  td {
    padding: 10px;
    border: 1px solid #16276a;

    @include sm {
      padding: 5px;
    }
  }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
    margin-left: 20px;
  }
}

.main-block:last-of-type {
  margin-bottom: 0;
}