.similar {
    background-color: $cyan_link;
    padding: 50px 0 100px;
    text-align: center;

    & + .search_center {
        margin-top: 40px;
    }

    .title_block {
        color: $white;
        margin-bottom: 40px;
    }
    

    .slider {
        .slick-dots {
            position: absolute;
            bottom: -36px;
            right: 50%;
            transform: translateX(50%);
            li {
                float: left;
                margin-right: 7px;
                button {
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    background-image: url("../images/ic_dots.webp"); /* Firefox */
                    background-image: -webkit-image-set(
                            url("../images/ic_dots.webp") 1x
                    );
                    background-image: image-set(
                            url("../images/ic_dots.png") 1x
                    ); /* Safari */
                    width: 15px;
                    height: 14px;
                    text-indent: -99999px;
                }
                &.slick-active button {
                    background-position: -22px;
                }
            }
        }
    }

    .item {
    }

    .center {
        background-color: $white;
        display: flex;
        padding: 20px;
        box-shadow: 1px 3px 9px rgba(110, 100, 170, 0.38);
        border-radius: 20px;
        background-color: $white;
        margin: 0 20px;
        height: 100%;
        box-sizing: border-box;


        @include sm {
            margin: 0;
        }

        .logo {
            border-radius: 20px;
            border: 1px solid $grey_border_logo;
            padding: 15px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            img {
                display: block;
                max-width: 60px;
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            text-align: left;

            .name {
                display: block;
                margin-bottom: 5px;
                color: $cyan_link;
                @include Panton(600);
                font-size: 14px;
                text-transform: uppercase;
            }

            .location {
                color: $black_light;
                @include OpenSans(400);
                font-size: 13px;
                line-height: 17px;
                margin-bottom: 10px;
            }

            .link {
                display: inline-block;
                margin-top: 0;
                border-radius: 15px;
                background-color: $cyan_link;
                padding: 8px 20px;
                color: $white;
                @include OpenSans(400);
                font-size: 14px;
            }
        }
    }

    &_link {
        margin-top: auto;
    }
}