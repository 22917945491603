$shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);

.articles {
  margin-bottom: 50px;

  .info_grid & {
    @include ms {
      height: calc(100% + 40px);
      display: flex;
      flex-direction: column;
    }

    .link {
      margin-top: auto;
      max-width: 200px;
      @include sm {
        margin-top: 20px;
        max-width: 100%;
      }
    }
  }


  & + .pagination {
    @include sm {
      margin-bottom: 40px;
    }
  }

  .item {
    display: flex;

    img {
      border-radius: 10px;
    }

    .col:first-child {
      margin-right: 10px;
      @include sm {
        float: left;
      }
    }

  }

  .link {
    @include link;
    margin-top: 20px;
    @include sm {
      width: 100%;
      text-align: center;
    }
  }

  &_sections {
    margin-bottom: 40px;

    .list {
      box-shadow: $shadow;
      border-radius: 20px;
      background-color: $white;

      .item {
        position: relative;
        padding: 10px 30px;

        &:nth-child(2n) {
          background-color: $grey_address_bg;
        }

        .title {
          color: $cyan_link;
          @include Panton(500);
          font-size: 16px;
        }

        &:after {
          content: '';
          background-repeat: no-repeat;
          background-position: 0 0;
          background-color: initial;
          background-image: url("../images/ic_arrows.webp"); /* Firefox */
          background-image: -webkit-image-set(
                          url("../images/ic_arrows.webp") 1x
          );
          background-image: image-set(
                          url("../images/ic_arrows.png") 1x
          ); /* Safari */
          position: absolute;
          top: calc(50% - 6px);
          right: 30px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  & + &_sections {
    margin-top: 65px;
    @include sm {
      margin-top: 25px;
      margin-bottom: 0;
    }
  }

  .banner + &, .banner + &_sections {
    margin-top: 30px;
  }

  &_content {
    margin-bottom: 50px;
    @include sm {
      margin: -10px 0 15px;
    }

    .title_block {
      @include sm {
        display: none;
      }
    }

    .list {
      a {
        position: relative;
        display: block;
        @include OpenSans(400);
        color: $cyan_link;
        font-size: 13px;
        line-height: 24px;
        margin: 20px 0;
        padding-left: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &:after {
          content: '';
          background-repeat: no-repeat;
          background-position: 0 0;
          background-color: initial;
          background-image: url("../images/ic_arrows.webp"); /* Firefox */
          background-image: -webkit-image-set(
                          url("../images/ic_arrows.webp") 1x
          );
          background-image: image-set(
                          url("../images/ic_arrows.png") 1x
          ); /* Safari */
          position: absolute;
          top: calc(50% - 6px);
          left: 0;
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .slick-slider {
    padding-bottom: 30px;

    .item {
      display: flex;
      @include sm {
        display: block;
      }
    }

    .slick-dots {
      li {
        margin-right: 7px;
        margin-top: 7px;
        display: inline-block;

        button {
          background-repeat: no-repeat;
          background-position: 0 0;
          background-color: transparent;
          background-image: url("../images/ic_dots.webp"); /* Firefox */
          background-image: -webkit-image-set(
                          url("../images/ic_dots.webp") 1x
          );
          background-image: image-set(
                          url("../images/ic_dots.png") 1x
          ); /* Safari */
          width: 15px;
          height: 14px;
          text-indent: -99999px;
        }

        &.slick-active button {
          background-position: -22px;
        }
      }
    }
  }
}

.articles__item {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.articles__item-pic,
.articles__item-img {
  width: 240px;
  display: flex;
}

.articles__item-name {
  display: block;
  color: $cyan_link;
  font-size: 16px;
  margin-bottom: 6px;
  @include Panton(500);

  &:hover {
    color: $purple_bg;
  }
}

.articles__item-desc {
  display: block;
  @include OpenSans(400);
  font-size: 13px;
  line-height: 18px;
}

.articles__item-read {
  display: inline-block;
  border-radius: 15px;
  background-color: $cyan_link;
  color: $white;
  padding: 2px 15px;
  @include OpenSans(400);
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
}