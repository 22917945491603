.cd-accordion {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cd-accordion--animated .cd-accordion__label::before {
    transition: transform .3s, -webkit-transform .3s
}

.cd-accordion__sub {
    display: none;
    overflow: hidden
}

.cd-accordion__sub--is-visible {
    display: block
}

.cd-accordion__item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.cd-accordion__input {
    position: absolute;
    opacity: 0
}

.cd-accordion__label {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.75em 1.25em;
    border-bottom: 1px dashed #ffffff;
    color: hsl(0, 0%, 0%);

    a {
        color: hsl(0, 0%, 0%);
    }
}

.cd-accordion__item:last-of-type {
    & > .cd-accordion__label {
        border-bottom: none;
    }
}

.cd-accordion__label span {
    -ms-flex-order: 3;
    order: 3
}

.cd-accordion__label:hover {
    background: hsla(0, 0%, 100%, 0.6);
}

.cd-accordion__label--icon-folder::before {
    content: '';
    display: block;
    min-width: 16px;
    min-height: 16px;
    background-image: url("../images/cd-icons.svg");
    background-repeat: no-repeat;
    margin-right: 0.25em;
    filter: brightness(0.7);
}

.cd-accordion__label--icon-folder::before {

}

.cd-accordion__label::after {
    -ms-flex-order: 2;
    order: 2
}

.cd-accordion__label--icon-folder::before {
    background-position: 0 0;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg)
}


.cd-accordion__label--icon-img::after {
    background-position: -48px 0
}

.cd-accordion__input:checked + .cd-accordion__label::before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0)
}

.cd-accordion__input:checked + .cd-accordion__label::after {
    background-position: -32px 0
}

.cd-accordion__input:checked ~ .cd-accordion__sub {
    display: block
}

.cd-accordion__sub--l1 .cd-accordion__label {
    padding-left: calc(1.25em + 16px);
}

.cd-accordion__sub--l1 .cd-accordion__label:hover {
    filter: brightness(1.1);
}

.cd-accordion__sub--l2 .cd-accordion__label {
    padding-left: calc(1.5em + 32px);
}

.cd-accordion__sub--l3 .cd-accordion__label {
    padding-left: calc(1.5em + 53px);
}

.cd-accordion__active {
    box-shadow: inset -110px 0 50px -50px #cac5ff;
}

.cd-accordion__sub--l1 {
    background: #e8edff;
}

.cd-accordion__sub--l2 {
    background: #d4ddfd;
}

.cd-accordion__sub--l3 {
    background: #cbd6ff;

    .cd-accordion__label {

    }
}

.cd-accordion_no-children {
  &::before {
    opacity: 0;
  }
}