.all-reviews {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -10px -20px;
  @include sm {
    margin: 0 0 -20px;
  }
}

.all-reviews__item {
  width: calc(50% - 62px);
  margin: 0 10px 20px;
  @include sm {
    width: 100%;
    margin: 0 0 20px;
  }

  background: url("../images/ic_quotes.png") calc(100% - 20px) 20px/ 45px no-repeat;
  padding: 20px;
  border: 1px solid #dfe2e5;
  border-radius: 8px;

  @include sm {
    background: none;
    border-radius: 0;
    padding: 0 0 20px;
    border: 0;
    border-bottom: 1px solid #dfe2e5;
  }
}

.all-reviews__name {
  display: block;
  margin-bottom: 20px;
  @include Panton(600);
  word-break: break-all;
  line-height: 22px;
  font-size: 20px;
  padding-right: 50px;
  color: #2fb7d3;
}

.all-reviews__stars {
  height: 15px;
  background: url('../images/ic_star_a.png');
  margin-bottom: 15px;
}

.all-reviews__title {
  color: #23282d;
  @include Panton(600);
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 22px;
}

.all-reviews__text {
  @include Panton(500);
  line-height: 22px;
  margin-bottom: 20px;
  font-size: 16px;
}

.all-reviews__date {
  background: url("../images/ic_date.png") 0 2px no-repeat;
  color: #8e9499;
  padding-left: 30px;
  line-height: 26px;
}