.pagination {
  margin-top: 25px;

  &__text {
    display: none;
  }

  &__link-list {

  }

  &__item {
    float: left;
    border: 1px solid #dfe2e5;
    overflow: hidden;

    &:not(:last-child) {
      border-right: 0;
    }

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &.pagination__more {
      display: none;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    width: 48px;
    height: 48px;
    color: #4f5458;
    @include Panton(600);
    font-size: 16px;
    line-height: 24px;

    &:hover, &.is-active {
      background-color: $cyan_link;
      color: #fff;
    }
  }
}