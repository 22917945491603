.booking-online {
  margin-left: 20px;
  display: flex;
  position: relative;

  @include sm {
    margin-left: 0;
  }

  &__button {
    color: #fff;
    font-family: Panton, Arial, sans-serif;
    font-weight: 500;
    padding: 12px 25px 10px;
    border-radius: 23px;
    font-size: 18px;
    line-height: 18px;

    @include sm {
      border-radius: 5px;
    }
  }

  &__submit {
    width: 100%;
  }
}

.booking-online__modal {
  display: none;
  position: absolute;
  background: $block_bg;
  padding: 20px;
  border-radius: 20px;
  right: 0;
  top: 50px;
  z-index: 2;
  width: 200px;
  box-shadow: 0 20px 40px -10px #9494ff;
}

.booking-online__input {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-family: inherit;
  min-height: 24px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
