@mixin sm() {
  @media only screen and (max-width: "1180px") {
    @content
  }
}

@mixin ms() {
  @media only screen and (min-width: "1180px") {
    @content
  }
}

@mixin media1024() {
  @media only screen and (max-width: "1024px") {
    @content
  }
}

@mixin media768() {
  @media only screen and (max-width: "768px") {
    @content
  }
}

@mixin media650() {
  @media only screen and (max-width: "650px") {
    @content
  }
}

@mixin media425() {
  @media only screen and (max-width: "425px") {
    @content
  }
}

@mixin smm() {
  @media only screen and (max-width: "360px") {
    @content
  }
}

@mixin clear {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

$radius_btn: 25px;
@mixin button($background: $black) {
  border-radius: $radius_btn;
  background-color: $background;
}

@mixin link() {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  @include button($purple_bg);
  color: $white;
  @include Panton(400);
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
}

@mixin input() {
  width: 100%;
  box-sizing: border-box;
  border-radius: 25px;
  border: 1px solid $grey_input_border;
  background-color: $white;
  padding: 15px 20px;

  color: $grey_input_text;
  @include OpenSans(400);
  font-size: 14px;
  line-height: 24px;

  outline: none;

  margin-bottom: 10px;
}