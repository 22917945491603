.switch {
    display: flex;
    justify-content: space-between;
    @include Panton(400);
    margin-bottom: 20px;

    @include media650 {
        flex-direction: column
    }
}

.switch__action-block {
    display: flex;
    border-radius: 50px;
    overflow: hidden;
    padding: 5px;
    background: $purple_bg;

    @include media650 {
        &:first-child {
            margin-bottom: 5px;
        }
    }
}

.switch__radio-button-label {
    padding: 15px 20px;
    display: block;
    border-radius: 50px;
    transition: 200ms;
    color: #fff;
    user-select: none;

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.9);
    }

    @include media650{
        width: 100%;
        text-align: center;
        padding: 15px 5px;
    }
}

.switch__radio-button-input {
    -webkit-appearance: none;
    margin: 0;
}

.switch__radio-button-input:checked + .switch__radio-button-label {
    color: $purple_text;
    background: #fff;

    &:hover,
    &:active {
        cursor: unset;
        transform: unset;
    }
}

.switch__content {
    display: none;

    &.active {
        display: block;
    }
}

.switch__content-link {
    display: block;
    @include Panton(400);
    margin-bottom: 5px;
}

.mrt-subways,
.kt-subways,
.mrt-area,
.kt-area {
    margin-bottom: 30px;
    column-count: 5;

    @include media1024 {
        column-count: 4;
    }

    @include media768 {
        column-count: 3;
    }

    @include media650 {
        column-count: 2;
    }

    @include media425 {
        column-count: 1;
    }
}