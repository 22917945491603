.reviews {
  margin-bottom: 30px;
  .info_grid & {
    @include ms {
      height: calc(100% + 40px);
      display: flex;
      flex-direction: column;
    }
    .link {
      margin-top: auto;
      max-width: 240px;
      text-align: center;
      @include sm {
        margin-top: 20px;
        max-width: 100%;
      }
    }
  }

  @include sm {
    margin-bottom: 40px;
  }
  .title_block {
    @include sm {
      text-align: center;
    }
  }
  .tabs_content & {
    .title_block {
      text-align: left;
    }
  }
  .slider {
    @include sm {
      padding-bottom: 40px;
      margin: 0 -20px;
    }
    @include smm {
      margin: 0;
    }
    .slick-slide {
      overflow: hidden;
    }
    .slick-dots {
      position: absolute;
      top: -35px;
      right: 0;
      @include sm {
        top: auto;
        bottom: 0px;
        right: 50%;
        transform: translateX(50%);
      }
      li {
        float: left;
        margin-right: 7px;
        button {
          background-repeat: no-repeat;
          background-position: 0 0;
          background-color: transparent;
          background-image: url("../images/ic_dots.webp"); /* Firefox */
          background-image: -webkit-image-set(
                  url("../images/ic_dots.webp") 1x
          );
          background-image: image-set(
                  url("../images/ic_dots.png") 1x
          ); /* Safari */
          width: 15px;
          height: 14px;
          text-indent: -99999px;
        }
        &.slick-active button {
          background-position: -22px;
        }
      }
    }
    .item {
      .review {
        @include sm {
          margin: 0 auto;
        }
      }
    }
  }
  .review {
    position: relative;
    width: 375px;
    height: 362px;
    box-sizing: border-box;
    padding: 25px;
    background-repeat: no-repeat;
    background-color: initial;
    background-position: 0 0;
    background-image: url("../images/bg_review.webp"); /* Firefox */
    background-image: -webkit-image-set(
            url("../images/bg_review.webp") 1x
    );
    background-image: image-set(
            url("../images/bg_review.png") 1x
    ); /* Safari */
    background-size: contain;
    color: $black;
    @include OpenSans(400);
    font-size: 13px;
    line-height: 20px;
    @include smm {
      width: 100%;
      background-size: cover;
    }
    a {
      color: $cyan_link;
      @include OpenSans(400);
    }
    .name {
      display: block;
      @include Panton(700);
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .desc {
      max-height: 180px;
      overflow-y: auto;
      margin-bottom: 10px;
      p {
        margin-bottom: 5px;
      }
    }
    .rating {
      position: relative;
      top: -30px;
      left: 25px;
      .stars {
        margin-bottom: 0;
      }
    }
    .bottom {
      position: absolute;
      bottom: 11px;
      left: 0;
      .author,
      .date {
        padding-left: 63px;
        display: block;
        color: $black;
        @include Panton(400);
        font-size: 13px;
        text-transform: uppercase;
      }
      .author {
        @include Panton(700);
        font-size: 16px;
        line-height: 1;
      }
    }
  }
  .link {
    @include link;
    @include sm {
      width: 100%;
      text-align: center;
    }
  }
  .grid {
    margin-bottom: 20px;
    flex-direction: column;
    @include sm {
      margin: 0 -40px;
    }
    .review {
      width: 100%;
      height: auto;
      background: #fff;
      box-shadow: 0 1px 12px $grey_shadow;
      border-radius: 20px;
      padding: 0;
      overflow: hidden;
      @include sm {
        border-radius: 0;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .top {
        padding: 15px 40px;
      }
      .bottom {
        padding: 15px 0 10px;
        position: relative;
        bottom: auto;
        background-color: $block_bg;
        background-image: url("../images/ic_quotes.webp"); /* Firefox */
        background-image: -webkit-image-set(
                url("../images/ic_quotes.webp") 1x
        );
        background-image: image-set(
                url("../images/ic_quotes.png") 1x
        ); /* Safari */
        background-position: 12px 15px;
        background-repeat: no-repeat;
        @include sm {
            padding: 15px 40px 10px;
            background-position: 40px 15px;
        }
      }
      .author,
      .date {
        padding-left: 53px;
        @include sm {
        }
      }
      .date {
          @include sm {
              margin-top: 5px;
          }
      }
      .rating {
        float: right;
        top: auto;
        left: auto;
        margin: 7px 15px 0 0;
        @include sm {
            position: absolute;
            margin: 0;
            bottom: 15px;
            left: 165px;
        }
      }
    }
  }
}

.review_form {
  box-shadow: 0 1px 12px $grey_shadow;
  border-radius: 20px;
  background-color: $white;
  padding: 30px 40px;
  margin-bottom: 20px;
  text-align: center;
  input,
  textarea {
    @include input;
  }
  textarea {
    margin-bottom: 15px;
    @include sm {
      min-height: 150px;
    }
  }
  button {
    @include link;
    width: 100%;
    margin-top: 15px;
  }
}
