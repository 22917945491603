.cta {
  font-size: 18px;
}

.cta__header {
  font-size: 37px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #ffffff;
  grid-column: 1 / 4;
  grid-row: 1 / 2;

  @include sm {
    font-size: 1.1em;
    margin-bottom: 15px;
  }
}

.cta__pluses {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;

  @include sm {
    list-style: disc;
    display: block;
    padding-left: 20px;
    margin-bottom: 10px;
  }
}

.cta__pluses-item {
  font-size: 18px;
  padding: 0 8px;
  border-left: 3px solid;
  margin: 7px 0;

  @include sm {
    font-size: 0.8em;
    width: auto;
    padding: 0;
    background: none;
    backdrop-filter: none;
    text-align: left;
    display: list-item;
    border-left: none;
  }
}

.cta__action {
  grid-column: 2 / 4;
  grid-row: 2 / 3;
  border-radius: 10px;
  backdrop-filter: saturate(106%) blur(2px);
  display: -ms-flexbox;
  display: flex;
  font-size: 23px;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  @include sm {
    font-size: 1em;
    display: block;
    padding: 0;
    background: none;
    text-align: center;
    border-top: 1px dashed;
    margin-top: 25px;
    padding-top: 10px;
    border-radius: 0;
  }
}

.cta__action-text {
  text-align: center;
  background: #3730afcf;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 10px 10px 0 0;
  color: #a68af3;
  font-weight: 500;

  @include sm {
    padding-bottom: 10px;
    background: none;
    color: #fff;
  }
}

.cta__action-phone {
  padding: 20px 50px;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 700;

  @include sm {
    font-size: 1.2em;
    display: block;
    text-align: center;
    padding: 15px;
  }
}