.leave-note {

}

.leave-note__btn {

}

.leave-note__form {
  display: grid;
  gap: 0 20px;
  grid:
        "input input"
        "button1 button2";

  @include sm {
    gap: 10px;
    grid:
        "input"
        "button1"
        "button2";

  }
}

.leave-note__input {
  width: 100%;
  grid-area: input;
  margin-bottom: 20px;

  @include sm {
    margin-bottom: 0;
  }
}

.leave-note__submit1 {
  grid-area: button1;
}

.leave-note__submit2 {
  grid-area: button2;
}