.articles-similar {
  margin-top: 50px;
}

.articles-similar__title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
}

.articles-similar__item {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
}

.articles-similar__item-pic,
.articles-similar__item-img {
  width: 100px;
  display: flex;
}

.articles-similar__item-name {
  display: block;
  color: $cyan_link;
  font-size: 16px;
  margin-bottom: 6px;
  @include Panton(500);

  &:hover {
    color: $purple_bg;
  }
}

.articles-similar__item-desc {
  display: block;
  @include OpenSans(400);
  font-size: 13px;
  line-height: 18px;
}
