footer {
    height: 308px;
    box-sizing: border-box;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        background-color: $cyan_link;
        width: 100%;
        height: 5px;
        left: 0;
        top: -5px;
    }

    .info {
        display: none;
        padding: 35px 0 30px 0;
        background-color: $purple_bg_dark;
        margin-bottom: 0;
        @include clear;

        @include sm {
            display: block;
            padding: 25px 0 20px 0;
        }

        .cont {
            display: flex;
            justify-content: space-between;
            @include sm {
                flex-direction: column;
            }
        }

        .col {
            &:first-child {
                max-width: 300px;
                @include sm {
                    max-width: 100%;
                    text-align: center;
                    margin-bottom: 20px;
                }
            }
            &:last-child {
                display: flex;

                @include sm {
                    flex-direction: column;
                    width: 100%;
                    text-align: center;
                }

                nav:first-of-type {
                    margin-right: 200px;

                    @include sm {
                        margin-right: 0;
                    }

                    .title {
                        margin: 26px 0 13px;
                        &:first-of-type {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .desc {
            margin-top: 15px;
            color: $white;
            @include OpenSans(300);
            font-size: 14px;
            line-height: 20px;
            @include sm {
                display: none;
            }
        }

        .phone {
            @include link;
            @include button($cyan_link);
            color: $white;
            margin-top: 30px;
            @include Panton(600);
            @include sm {
                width: 100%;
            }
        }

        nav {
            @include sm {
                //max-width: 320px;
            }
            a, a.title {
                display: block;
                color: $cyan_link;
                @include Panton(400);
                font-size: 16px;

                &:not(:last-child) {
                    margin-bottom: 24px;
                }
            }
        }
    }

    .bottom {
        @include clear;
        background-color: $purple_bg_bottom;
        padding: 25px 0;

        .footer__link,
        .menu_item > a {
            color: $purple_text_bottom;
            @include Panton(400);
            font-size: 15px;
            text-transform: uppercase;
            &:hover {
                color: $white;
            }
        }

        .menu {
            margin-right: 20px;
        }

        .left {
            color: $purple_text_copy;
            @include OpenSans(300);
            font-size: 14px;
            line-height: 24px;
            @include sm {
                float: none;
                text-align: center;
            }
        }

        .right {
            display: flex;
            line-height: 24px;

            @include sm {
                display: none;
            }
        }
    }
}

.footer {
    color: #fff;
    padding-top: 75px;
    background-color: $purple_text;
    grid-area: footer;
}

.footer__top {
    display: grid;
    grid-gap: 2%;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 35px;

    @include sm {
        grid-template-columns: repeat(4, 1fr);
    }

    @include sm {
        grid-template-columns: repeat(2, 1fr);
    }

    @include sm {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
}

.footer__mobile-list {
    display: none;

    @include sm {
        display: flex;
        justify-content: center;
        grid-gap: 10%;
        margin-bottom: 20px;
    }
}

.footer__info {
    text-align: center;
    font-size: 14px;

    @include sm {
        grid-column: 1/5;
        margin-bottom: 20px;
    }

    @include sm {
        grid-column: 1/3;
    }
}

.footer__logo:before {
    content: '';
    background: url(../images/logo_mini.png) 50% no-repeat;
    background-size: 55px 55px;
    height: 55px;
    margin-bottom: 13px;
    display: inline-block;
    width: 55px;
}

.footer__logo-title {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include sm {
        font-size: 14px;
    }
}

.footer__text {
    margin: 10px 0;
    font-size: 14px;
    font-weight: 600
}

.footer__phone {
    font-size: 23px;
    font-weight: 700;
    color: $yellow_act;

    @include sm {
        font-size: 20px;
    }
}

.footer__list {
    @include sm {
        display: none;

        &:last-child {
            display: block;

            & > .footer__list-item {
                display: none;
            }

            & > .footer__social-links {
                display: grid;
                justify-content: center;

                span {
                    grid-column: 1 / 3;
                    margin-bottom: 25px;
                }

                .footer__social-link {
                    justify-self: center;
                    width: 50px;
                    height: 50px;
                    margin-left: 0;
                }
            }
        }
    }
}

.footer__list-name {
    color: #fff;
    margin-bottom: 10px;
    font-weight: 600;
    display: block;
}

a.footer__link {
    font-size: 14px;
    color: #aadbff
}

.footer__social-links-mob { //todo
    display: none;
    margin: 10px 0
}

.footer__social {
    &-links {
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 8px;
    }

    &-link {
        display: inline-block;
        margin-left: 6px;
        width: 18px;
        height: 18px;
        vertical-align: middle;
        background-size: contain;

        &-vk {
            background-image: url(../img/vk.svg);
        }

        &-fb {
            background-image: url(../img/svg/F_icon.svg);
        }
    }
}

.footer__bottom {
    font-size: 12px;
    text-align: center;
    background-color: $purple_bg_bottom;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    padding: 20px;

    @include sm {
        grid-gap: 5px;
        flex-direction: column;
    }
}