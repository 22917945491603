// сбрасываем стандартные стили балуна
[class*='-balloon'] {
    box-shadow: none!important;
    border: 0!important;
    padding: 0!important;
    margin: 0!important;
    background: rgba(0, 0, 0, 0)!important
}
[class*='-balloon__layout'] {
	overflow: visible!important;
}
	
[class*='-balloon__close-button'] {
    background-repeat: no-repeat!important;
    background-position: 0 0;
    background-color: initial;
    background-image: url("../images/ic_balloon_close.webp"); /* Firefox */
    background-image: -webkit-image-set(
            url("../images/ic_balloon_close.webp") 1x
    );
    background-image: image-set(
            url("../images/ic_balloon_close.png") 1x
    ); /* Safari */
	width: 20px!important;
	height: 20px!important;
    position: absolute;
    top: 15px;
    right: 15px;
	opacity: 1!important;
}
[class*='-balloon__tail'] {
    left: 145px !important;
    top: -18px;
    z-index: 1;
}

// сам балун
.ballon {
    width: 320px;
    box-sizing: border-box;
    box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.35);
    border: 1px solid $grey_shadow_ballon;
    border-radius: 25px!important;
    background: $white;
    margin: 20px;
    padding: 15px;
    text-align: center;

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .rating {
        width: 87px;
        .stars {
            width: 100%;
            height: 13px;
            span {
                height: 13px;
            }
        }
    }

    .location, .staff {
        color: #000000;
        @include OpenSans(400);
        font-size: 12px;
        line-height: 17px;
        a {
            color: $cyan_link;
        }
    }

    .phone {
        display: inline-block;
        text-align: center;
        margin: 0 auto;
        @include Panton(600);
        @include button($purple_bg);
        color: $white;
        padding: 14px 35px 10px;
        margin: 15px auto 0;
    }
}