.banner {
    width: 330px;
    height: 270px;
    box-sizing: border-box;
    padding: 60px 40px 0;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: initial;
    background-image: url("../images/bg_banner.webp"); /* Firefox */
    background-image: -webkit-image-set(
            url("../images/bg_banner.webp") 1x
    );
    background-image: image-set(
            url("../images/bg_banner.png") 1x
    ); /* Safari */
    background-size: contain;

    color: $white_light;
    @include Panton(500);
    font-size: 20px;
    text-align: center;

    .text_block & {
        margin-bottom: 20px;
    }

    .doctors + &.purple {
        @include sm {
            margin-top: 30px;
        }
    }

    &.purple {
        width: auto;
        height: auto;
        background-image: url("../images/bg_decor.webp"); /* Firefox */
        background-image: -webkit-image-set(
                url("../images/bg_decor.webp") 1x
        );
        background-image: image-set(
                url("../images/bg_decor.png") 1x
        ); /* Safari */
        background-size: cover;
        background-color: $purple_bg;
        padding: 40px;
        box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
        border-radius: 20px;

        @include sm {
            padding: 20px;
        }

        .title {
            margin-bottom: 20px;

            @include sm {
                b {
                    color: $yellow_btn;
                }
            }
        }

        .info {
            margin-bottom: 20px;
        }
    }
    
    .info {
        text-align: left;
        padding-left: 20px;

        .location, .metro {
            display: block;
            position: relative;
            color: $white;
            @include OpenSans(400);
            font-size: 12px;
            line-height: 22px;
            

            &:before {
                content: '';
                position: absolute;
                background-repeat: no-repeat;
                background-color: initial;
                background-position: 0 0;
                background-image: url("../images/ic_department.webp"); /* Firefox */
                background-image: -webkit-image-set(
                        url("../images/ic_department.webp") 1x
                );
                background-image: image-set(
                        url("../images/ic_department.png") 1x
                ); /* Safari */
                width: 14px;
                height: 16px;
                top: calc(50% - 4px);
                transform: translateY(-50%);
                left: -24px;
            }
        }

        .metro {
            color: $cyan_link;
            &:before {
                background-image: url("../images/ic_metro.webp"); /* Firefox */
                background-image: -webkit-image-set(
                        url("../images/ic_metro.webp") 1x
                );
                background-image: image-set(
                        url("../images/ic_metro.png") 1x
                ); /* Safari */
                width: 21px;
                height: 15px;
                top: calc(50% - 3px);
                left: -27px;
            }
        }
    }

    .title {
        font-size: 22px;
        line-height: 28px;
        b {
            @include Panton(700);
        }
    }

    .cta {
        margin-top: 15px;
    }

    .text {
        display: block;
        @include Panton(400);
        font-size: 16px;
    }

    .phone {
        margin-top: 5px;
        @include button($yellow_btn);
        display: inline-block;
        padding: 12px 35px 10px;
        color: $purple_text;
        @include Panton(800);
        font-size: 20px;
        @include sm {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}