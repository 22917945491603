.prices {
  margin-bottom: 35px;
  .list {
    width: 100%;
    box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
    border-radius: 20px;
    background-color: $white;
    overflow: hidden;
    @include sm {
      margin: 0 -40px;
      width: 100vw;
      border-radius: 0;
    }
    @include smm {
      margin: 0 -20px;
    }
    thead {
      tr {
        background-color: inherit !important;
      }
      th {
        @include Panton(500);
        color: $grey_table_text;
        text-transform: uppercase;
        text-align: left;
        &:first-child {
          width: 50%;
          @include sm {
            display: none;
          }
        }
        &:nth-child(2) {
          @include sm {
            padding-left: 40px;
          }
        }
        &:nth-child(4) {
          @include sm {
            padding-right: 40px;
          }
        }
      }
    }
    tr {
      //padding: 10px 20px;
      &:nth-child(2n + 1) {
        background-color: $grey_btn_bg;
      }
      &.list_name {
        background-color: $cyan_link !important;
        td {
          color: $white;
        }
      }
      td {
        &:first-child {
          @include sm {
            padding-left: 40px;
          }
        }
        &:last-child {
          @include sm {
            padding-right: 40px;
          }
        }
      }
    }
    th,
    td {
      padding: 15px 20px;
      color: $black;
      @include OpenSans(400);
      font-size: 13px;
      line-height: 16px;
      @include sm {
        font-size: 12px;
        padding: 10px 5px;
      }
    }
    a {
      color: $black;
      &:hover {
        color: $cyan_link;
        text-decoration: none;
        @include sm {
            color: $white;
            text-decoration: underline;
        }
      }
    }
    tr.service {
      background-color: $cyan_link;

      @include sm {
          background-color: $purple_bg;
          border-bottom: 1px solid $white;
          td {
            font-size: 18px;
            line-height: 20px;
          }
      }

      &.active {
        &~tr {
          display: table-row;
          &:hover {
            background-color: $cyan_tr_hover !important;
            @include sm {
                background: $cyan_link !important;
            }
          }
        }
      }
      td {
        color: $white;
      }
      &~tr {
        display: none;
      }
    }
  }
  .link {
    @include link;
    @include sm {
      width: 100%;
      text-align: center;
    }
  }
}

/* Можно оптимизировать: удалить верхний код, который отвечает за теги table и сопутствующие ему (код нах-ся во вкладке Инфа о центре)*/

.pricelist__table {
  .collapse {
    display: block;
    width: 13px;
    height: 7px;
    transform: rotate(180deg);
    background-color: initial;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: url("../images/ic_arrow_top.webp"); /* Firefox */
    background-image: -webkit-image-set(
            url("../images/ic_arrow_top.webp") 1x
    );
    background-image: image-set(
            url("../images/ic_arrow_top.png") 1x
    ); /* Safari */
    background-size: contain;
    float: right;
  }
}

.pricelist__spec.active {
  .pricelist__spec-items {
    display: block;
  }

  .collapse {
    transform: rotate(0deg);
  }
}

.pricelist__spec-items {
  display: none;
}

.pricelist__table-header,
.pricelist__spec-item,
.pricelist__spec-header {
  display: grid;
  grid-template-columns: 3.5fr 1fr 1.3fr 1.3fr;
}
.pricelist__spec-item {
  @include sm {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.pricelist__spec-header {
  background-color: $cyan_link;
  grid-template-columns: 1fr 1fr;

  @include sm {
    padding: 0 40px;
    background-color: $purple_bg;
    border-bottom: 1px solid $white;
  }
}

.pricelist__spec-item:nth-child(odd) {
    background-color: #f1f4f5;
}

.pricelist__spec-item:hover {
    background-color: $cyan_tr_hover;
}

.pricelist__cell,
.pricelist__th-cell,
.pricelist__spec-name {
  padding: 15px 20px;
  color: #000;
  font-family: OpenSans,Arial,sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.pricelist__spec-name {
  color: #fff;

  @include sm {
    font-size: 18px;
    line-height: 20px;
    padding: 15px 0;
  }
}

.pricelist__th-cell {
  font-family: Panton,Arial,sans-serif;
  font-weight: 500;
  color: #95a2a4;
  text-transform: uppercase;
  text-align: left;
}

.pricelist__button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@include sm {
    $cell-padding: 40px;

  .pricelist__cell {
    font-size: 12px;
    padding: 10px 0;

    &:nth-child(3) {
      padding-left: $cell-padding;
    }

    &:nth-child(5) {
      padding-right: $cell-padding;
    }
  }

    .pricelist__th-cell {
        font-size: 12px;
        padding: 10px 0;

        &:nth-child(2) {
            padding-left: $cell-padding;
        }

        &:nth-child(4) {
            padding-right: $cell-padding;
        }
    }

  .pricelist__spec-item:nth-child(odd) {
    background: #fff;
  }
  
  .pricelist__service-name {
    grid-column-start: 1;
    grid-column-end: 4;
    background-color: $cyan_link;
    color: #fff;
    padding: 10px $cell-padding;
  }

  .pricelist__th-cell:first-child {
    display: none;
  }

  .pricelist__table-header {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@include smm {
    $cell-padding: 20px;

    .pricelist__cell {
        &:nth-child(3) {
            padding-left: $cell-padding;
        }

        &:nth-child(5) {
            padding-right: $cell-padding;
        }
    }

    .pricelist__th-cell {
        &:nth-child(2) {
            padding-left: $cell-padding;
        }

        &:nth-child(4) {
            padding-right: $cell-padding;
        }
    }

    .pricelist__service-name {
        padding: 10px $cell-padding;
    }

    .pricelist__spec-header {
        padding: 0 $cell-padding;
    }
}
