.popular {

}

.popular__header {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.popular__body {
  display: flex;
  gap: 20px;
  flex-flow: wrap;
  width: 100%;
}

.popular__item {
  background: #fff;
  padding: 20px;
  white-space: nowrap;
  border-radius: 10px;
  flex-grow: 1;
  text-align: center;

  @include sm {
    white-space: normal;
  }
}