.doctor {
  margin-bottom: 40px;
  .bread + & {
    @include sm {
      margin-top: -20px;
    }
  }
  .cont {
    @include sm {
      padding: 0;
    }
  }
  .grid {
    box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
    border-radius: 20px;
    background-color: $white;
    @include sm {
      border-radius: 0;
      padding: 20px 40px;
    }
    @include smm {
      padding: 20px;
    }
  }
  .avatar {
    margin: 30px;
    @include sm {
      margin: 0 20px 0 0;
      float: left;
    }
    @include smm {
      margin-right: 10px;
    }
    img {
      max-width: 100px;
      max-height: 100px;
      border-radius: 10px;
      border: 1px solid $grey_btn_border;

      @include sm {
        max-width: 115px;
        height: auto;
      }
      @include smm {
        max-width: 80px;
      }
    }
  }
  .body {
    padding: 30px 0;
    padding-right: 100px;
    @include sm {
      padding: 0;
      margin: 0;
    }
    .top {
      padding: 0 0 20px;
      @include sm {
        @include clear;
      }
    }

    .phone {
      margin-top: 25px;
      span {
        display: block;
        @include Panton(500);
        color: $purple_bg;
        font-size: 16px;
        text-align: center;
      }
      a {
        @include link;
        width: 100%;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
  .name {
    display: block;
    color: $purple_bg;
    @include Panton(500);
    font-size: 25px;
    @include sm {
      margin-bottom: 25px;
    }
  }
  .exp {
    display: inline-block;
    margin-top: 5px;
    border-radius: 13px;
    background-color: rgba($cyan_link, 0.2);
    color: $black;
    @include OpenSans(400);
    font-size: 14px;
    line-height: 22px;
    padding: 3px 13px;
    @include sm {
      margin: 0 0 10px 0;
    }
  }
  .desc {
    color: $black_light;
    @include Panton(400);
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    @include sm {
      font-size: 14px;
    }
  }
  .spot {
    margin-bottom: 20px;
    a, span {
      color: $cyan_link;
      @include Panton(400);
      font-size: 16px;
      line-height: 22px;
    }
  }
  .center {
    @include link;
    background-color: $cyan_link;
    color: $white;

    @include sm {
      width: 100%;
      text-align: center;
      background-color: rgba($cyan_link, 0.2);
      color: $black;
    }
    @include smm {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .banner {
    margin: 10px 30px 5px 0;
  }
  .right {
    @include sm {
      display: none;
    }
  }
}

.doctors {
  text-align: center;
  .right & {
    margin-bottom: 35px;
  }
  .title_block {
    text-align: left;
  }
  .wrap {
    box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
    border-radius: 20px;
    background-color: $white;
  }
  .grid {
    box-shadow: none;
    border-radius: 0;
    background-color: inherit;
    padding: 0;
  }
  .slider {
    .slick-dots {
      position: absolute;
      bottom: 20px;
      right: 50%;
      transform: translateX(50%);
      li {
        float: left;
        margin-right: 7px;
        button {
          background-repeat: no-repeat;
          background-position: 0 0;
          background-color: initial;
          background-image: url("../images/ic_dots.webp"); /* Firefox */
          background-image: -webkit-image-set(
                  url("../images/ic_dots.webp") 1x
          );
          background-image: image-set(
                  url("../images/ic_dots.png") 1x
          ); /* Safari */
          width: 15px;
          height: 14px;
          text-indent: -99999px;
        }
        &.slick-active button {
          background-position: -22px;
        }
      }
    }
    .item {
      .outer {
        display: flex;
        justify-content: space-between;
      }
    }
    .doctor {
      @include sm {
        padding: 15px 15px 30px;
      }
      .center {
        @include sm {
          width: 100%;
          box-sizing: border-box;
        }
      }
    }
  }
  .doctor {
    width: 100%;
    padding: 30px;
    margin-bottom: 25px;
    .avatar {
      margin: 0 20px 0 0;
    }
    .top {
      display: flex;
      flex-direction: column;
      text-align: left;
    }
    .name {
      display: block;
      color: $cyan_link;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    .exp {
      display: inline-block;
      margin-left: 0;
      margin-bottom: 10px;
    }
    .spot {
      a {
        font-size: 14px;
      }
    }
    .body {
      margin: auto 0 0 0;
      padding: 20px 0 0 0;
      text-align: center;
    }
    .desc {
      color: $black;
      @include OpenSans(300);
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 30px;
      text-align: left;
    }
    .center {
      font-size: 16px;
      background-color: $cyan_link;
      color: $white;
    }
  }
  .link {
    @include link;
    @include sm {
      width: 100%;
      text-align: center;
    }
  }
  .content & {
    .wrap {
      box-shadow: none;
      border-radius: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include sm {
        flex-direction: column;
      }
    }
    .doctor {
      width: calc(50% - 10px);
      box-sizing: border-box;
      box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
      border-radius: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      @include sm {
        width: 100%;
      }
      .center {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 0 10px;
        span {
          color: $white_light;
          @include OpenSans(400);
          font-size: 11px;
          margin-right: 5px;
          text-transform: uppercase;
          @include sm {
            display: none;
          }
        }
      }
    }
  }
}
