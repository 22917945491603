.advantages {
    margin-bottom: 50px;
    background-color: $purple_bg;
    padding: 50px 0;
    color: $white;
    text-align: center;

    .grid {
        padding: 0 50px;
        justify-content: space-between;


        @include sm {
            flex-direction: column;
            padding: 0 20px;
        }
    }

    .title_block {
        margin-bottom: 60px;
        color: $white;
    }

    .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        @include sm {
            justify-content: start;
            align-items: center;
            flex-direction: row;
            min-height: 86px;
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            $size : 127px;
            content: '';
            width: #{$size};
            height: #{$size};
            background-color: $purple_bg_light;
            border-radius: 50%;

            @include sm {
                $size : 86px;
                width: #{$size};
                height: #{$size};
                margin-right: 15px;
            }
        }

        img {
            display: block;
            position: relative;
            z-index: 1;
            top: -20px;
            @include sm {
                top: 0;
            }
        }

        span {
            display: block;
            margin-top: 5px;
            @include Panton(500);
            font-size: 16px;
            line-height: 20px;
            position: relative;
            z-index: 1;
            top: -55px;
            @include sm {
                text-align: left;
                top: 0;
            }
        }
    }

    .link {
        @include link;
        @include button($cyan_link);
        color: $white;
        margin-top: 0;
        @include sm {
            margin-top: 60px;
            width: 100%;
            padding: 12px 0 10px;
        }
    }
}