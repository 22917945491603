.about {
    margin-bottom: 40px;

    @include sm {
        margin-bottom: 20px;
    }

    .content {
        @include sm {
            .price, .text_block {
                display: none!important;
            }

            &.left {
                text-align: center;
                .title_block {
                    margin-bottom: 0;
                }   
            }
        }

        .price {
            display: inline-block;
            background-color: $grey_address_bg;
            border-radius: 20px;
            padding: 10px 30px;
            color: $black_dark;
            @include Panton(400);
            font-size: 17px;
            line-height: 22px;
        }
    }

    .left {
        @include sm {
            margin-bottom: 20px;
        }
    }

    .main + & {
        background-color: $grey_bg;
        margin: -50px 0 0 0;
        padding: 84px 0 37px 0;

        @include sm {
            margin: 0;
            padding: 40px 0 25px;

            .cont {
                display: block;
            }
        }
    }
}