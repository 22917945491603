$white: #fff;
$white_light: #fefefe;
$black: #000;
$black_dark: #010101;
$black_light: #333333;
$black_light_more: #283746;

$grey_bg: #fcfcfd;
$grey_light: #fdfdfe;
$grey_text: #bcbbbd;
$grey_text_mid: #c0cdcf;
$grey_text_dark: #999899;
$grey_border: #000203;
$grey_shadow: #e0e7e7;
$grey_border_logo: #f1f2f7;
$grey_address_bg: #f1f7f8;
$grey_address_text: #888c8c;
$grey_total_text: #9ba0a0;
$grey_shadow_ballon: #dce2e3;
$grey_btn_bg: #f1f4f5;
$grey_btn_border: #edf2f3;
$grey_table_text: #95a2a4;
$grey_input_border: #cfd9de;
$grey_input_text: #9ea3a6;
$grey_bg: #fafafb;
$block_bg: #f5f7ff;
$grad_button: linear-gradient(90deg,#6140e5,#6a55db 50%,#ba45fc);

$cyan_link: #08b7d7;
$cyan_border: #069eba;
$cyan_tr_hover: #f2fbfe;

$yellow_text: #ffd737;
$yellow_btn: #ffcc00;
$yellow_act: #fff700;

$green_bg: #1dda6d;

$purple_link_bottom: #9f9bb9;
$purple_text: #2d256a;
$purple_bg: #523fa4;
$purple_bg_light: #5945ac;
$purple_bg_dark: #3c2d7d;
$purple_bg_bottom: #221752;
$purple_text_bottom: #6757aa;
$purple_text_copy: #503f9b;