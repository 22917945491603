@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*
    Open Sans
*/
@font-face {
  font-family: OpenSans;
  src: url("../fonts/OpenSans-Light.woff2") format("woff2"), url("../fonts/OpenSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  src: url("../fonts/OpenSans-Regular.woff2") format("woff2"), url("../fonts/OpenSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  src: url("../fonts/OpenSans-SemiBold.woff2") format("woff2"), url("../fonts/OpenSans-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  src: url("../fonts/OpenSans-Bold.woff2") format("woff2"), url("../fonts/OpenSans-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  src: url("../fonts/OpenSans-ExtraBold.woff2") format("woff2"), url("../fonts/OpenSans-ExtraBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/*
    Panton
*/
@font-face {
  font-family: Panton;
  src: url("../fonts/Panton-Light.woff2") format("woff2"), url("../fonts/Panton-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Panton;
  src: url("../fonts/Panton-Regular.woff2") format("woff2"), url("../fonts/Panton-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Panton;
  src: url("../fonts/Panton-SemiBold.woff2") format("woff2"), url("../fonts/Panton-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Panton;
  src: url("../fonts/Panton-Bold.woff2") format("woff2"), url("../fonts/Panton-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Panton;
  src: url("../fonts/Panton-ExtraBold.woff2") format("woff2"), url("../fonts/Panton-ExtraBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Panton;
  src: url("../fonts/Panton-Black.woff2") format("woff2"), url("../fonts/Panton-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:focus {
  outline: none;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

button:hover {
  text-decoration: underline;
}

select {
  display: none;
}

.wrap {
  position: relative;
}

.cont {
  width: 1180px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

@media only screen and (max-width: 1180px) {
  .cont {
    width: 100%;
    padding: 0 24px;
  }
}

@media only screen and (max-width: 360px) {
  .cont {
    padding: 0 20px;
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

.grid {
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .desktop {
    display: none;
  }
}

@media only screen and (min-width: 1180px) {
  .mobile {
    display: none !important;
  }
}

.map_open {
  overflow: hidden;
}

.lazy {
  background-image: none !important;
}

html, body {
  height: 100%;
  overflow: auto;
}

body {
  background-color: #fff;
  font-family: Panton,Arial,sans-serif;
}

header:after,
footer:after,
section:after {
  content: "";
  display: table;
  clear: both;
}

.title_block {
  color: #000;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 25px;
  margin-bottom: 15px;
  line-height: 35px;
}

@media only screen and (max-width: 1180px) {
  .title_block {
    font-size: 20px;
    line-height: 25px;
  }
}

.text_block {
  color: #000;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.text_block b {
  line-height: 25px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
}

.text_block p {
  margin-bottom: 15px;
}

@media only screen and (max-width: 1180px) {
  .text_block img {
    width: 100%;
  }
}

.text_block ul {
  list-style: none;
  margin-bottom: 15px;
}

.text_block ul li {
  display: flex;
  align-items: top;
}

.text_block ul li:not(:last-child) {
  margin-bottom: 5px;
}

.text_block ul li:before {
  content: "•";
  color: #08b7d7;
  font-size: 50px;
  margin-right: 5px;
}

.text_block ol {
  list-style: none;
  margin-bottom: 15px;
  counter-reset: li;
}

.text_block ol li {
  display: flex;
  align-items: top;
  counter-increment: li;
}

.text_block ol li:not(:last-child) {
  margin-bottom: 5px;
}

.text_block ol li:before {
  content: counter(li) ".";
  color: #08b7d7;
  margin-right: 5px;
  min-width: 10px;
}

.text_block h6 {
  font-size: 4.17px;
  margin-bottom: 15px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
}

.text_block h5 {
  font-size: 8.34px;
  margin-bottom: 15px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
}

.text_block h4 {
  font-size: 12.51px;
  margin-bottom: 15px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
}

.text_block h3 {
  font-size: 16.68px;
  margin-bottom: 15px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
}

.text_block h2 {
  font-size: 20.85px;
  margin-bottom: 15px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
}

.text_block h1 {
  font-size: 25.02px;
  margin-bottom: 15px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
}

.text_block table {
  width: 100%;
  box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1180px) {
  .text_block table {
    margin: 0 -40px 20px;
    width: calc(100% + 80px);
    border-radius: 0;
  }
}

@media only screen and (max-width: 360px) {
  .text_block table {
    margin: 0 0 20px;
  }
}

.text_block table thead tr {
  background-color: inherit !important;
}

.text_block table thead th {
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  color: #95a2a4;
  text-transform: uppercase;
  text-align: left;
}

.text_block table thead th:first-child {
  width: 50%;
}

@media only screen and (max-width: 1180px) {
  .text_block table thead th:first-child {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 1180px) {
  .text_block table thead th:last-child {
    padding-right: 40px;
  }
}

.text_block table tr:nth-child(2n + 1) {
  background-color: #f1f4f5;
}

.text_block table tr td:first-child {
  width: 50%;
}

@media only screen and (max-width: 1180px) {
  .text_block table tr td:first-child {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 1180px) {
  .text_block table tr td:last-child {
    padding-right: 40px;
  }
}

.text_block table th, .text_block table td {
  padding: 15px 20px;
  color: #000;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
}

@media only screen and (max-width: 1180px) {
  .text_block table th, .text_block table td {
    padding: 10px;
  }
}

.text_block table a {
  color: #000;
}

.text_block table a:hover {
  color: #08b7d7;
  text-decoration: none;
}

.rating {
  text-align: center;
  overflow: hidden;
}

.rating a {
  color: #08b7d7;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  text-decoration: none;
}

.rating a:hover {
  color: #523fa4;
}

.rating .stars {
  display: block;
  width: 105px;
  height: 16px;
  background-color: initial;
  background-image: url("../images/ic_star.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_star.webp") 1x);
  background-image: image-set(url("../images/ic_star.png") 1x);
  /* Safari */
  margin-bottom: 5px;
}

.rating .stars span {
  display: block;
  height: 16px;
  background-color: initial;
  background-image: url("../images/ic_star_a.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_star_a.webp") 1x);
  background-image: image-set(url("../images/ic_star_a.png") 1x);
  /* Safari */
}

.rating_user {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rating_user .text {
  color: #000;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-right: 5px;
}

@media only screen and (max-width: 1180px) {
  .rating_user .text {
    font-size: 14px;
  }
}

.rating_user .stars {
  display: flex;
  position: relative;
  top: -2px;
}

.rating_user .stars.hovered .hover {
  background-image: url("../images/ic_star_a.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_star_a.webp") 1x);
  background-image: image-set(url("../images/ic_star_a.png") 1x);
  /* Safari */
}

.rating_user .stars.selected:not(.hovered) .select {
  background-image: url("../images/ic_star_a.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_star_a.webp") 1x);
  background-image: image-set(url("../images/ic_star_a.png") 1x);
  /* Safari */
}

.rating_user .stars .star {
  width: 16px;
  height: 15px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-color: initial;
  background-image: url("../images/ic_star.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_star.webp") 1x);
  background-image: image-set(url("../images/ic_star.png") 1x);
  /* Safari */
  background-size: cover;
  cursor: pointer;
}

.rating_user .stars .star:not(:last-child) {
  margin-right: 5px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
}

@media only screen and (max-width: 1180px) {
  .pagination {
    flex-direction: column;
    padding: 0 40px;
  }
}

.pagination .more {
  border-radius: 25px;
  background-color: #523fa4;
  padding: 15px 60px;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1180px) {
  .pagination .more {
    width: 100%;
    padding: 11px 60px;
    background-color: #08b7d7;
    color: #fff;
    margin-bottom: 5px;
  }
}

.pagination .total {
  margin-left: 20px;
  color: #523fa4;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.pagination.pages {
  justify-content: start;
  margin-top: 0;
  flex-direction: row;
}

@media only screen and (max-width: 1180px) {
  .pagination.pages {
    margin-top: 20px;
    padding: 0;
    justify-content: center;
  }
}

.pagination.pages .list {
  display: flex;
  justify-content: start;
}

.pagination.pages a {
  border-radius: 15px;
  border: 1px solid #edf2f3;
  background-color: #fff;
  color: #283746;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 2px 12px;
}

@media only screen and (max-width: 1180px) {
  .pagination.pages a {
    padding: 2px 8px;
  }
}

.pagination.pages a:hover {
  text-decoration: none;
  background-color: #08b7d7;
  color: #fff;
}

.pagination.pages a.item {
  border-radius: 0;
}

.pagination.pages a.item:not(:first-child) {
  border-left: 0;
}

.pagination.pages a.item:first-child {
  border-radius: 15px 0 0 15px;
}

.pagination.pages a.item:last-child {
  border-radius: 0 15px 15px 0;
}

.pagination.pages a.prev {
  margin-right: 10px;
}

.pagination.pages a.next {
  margin-left: 10px;
}

.bread {
  margin-bottom: 20px;
  position: relative;
  padding: 0 40px;
}

@media only screen and (max-width: 1180px) {
  .bread {
    padding: 0 24px;
  }
}

.bread a {
  color: #08b7d7;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
}

.bread__item:last-child:not(:first-child) a {
  color: #000;
  cursor: default;
}

.bread__item:last-child:not(:first-child) a:hover {
  text-decoration: none;
}

.bread__item:not(:last-child):after {
  content: '';
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/ic_breadcrumbs.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_breadcrumbs.webp") 1x);
  background-image: image-set(url("../images/ic_breadcrumbs.png") 1x);
  /* Safari */
  width: 5px;
  height: 10px;
  display: inline-block;
  position: relative;
  margin: 0 5px 0 10px;
  top: 1px;
}

@media only screen and (min-width: 1180px) {
  .info_grid {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
  }
}

section.search_center {
  margin-bottom: 40px;
}

section.search_center .cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1180px) {
  section.search_center .cont {
    flex-direction: column;
  }
}

section.search_center .title {
  color: #000;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 25px;
  margin-right: 60px;
}

@media only screen and (max-width: 1180px) {
  section.search_center .title {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 1180px) {
  section.search_center .grid {
    flex-direction: column;
    width: 100%;
  }
}

section.search_center .grid .item {
  justify-content: space-between;
}

@media only screen and (max-width: 1180px) {
  section.search_center .grid .item {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    margin: 0;
  }
}

section.search_center .grid .item:not(:last-child) {
  margin-right: 50px;
}

@media only screen and (max-width: 1180px) {
  section.search_center .grid .item:not(:last-child) {
    margin: 0 0 10px;
  }
}

section.search_center .item {
  border-radius: 33px;
  background-color: #f1f4f5;
  padding: 25px 60px 21px;
  color: #523fa4;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
}

@media only screen and (max-width: 1180px) {
  section.search_center .item {
    padding: 16px 20px;
    font-family: Panton, Arial, sans-serif;
    font-weight: 500;
  }
}

.hamburger .line {
  width: 22px;
  height: 3px;
  background-color: #fff;
  display: block;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(2) {
  margin: 5px auto;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger.is-active .line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active .line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.to_top {
  width: 45px;
  height: 45px;
  box-shadow: 0 3px 9px rgba(110, 100, 170, 0.38);
  background-color: #523fa4;
  border-radius: 50%;
  background-image: url("../images/ic_to_top.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_to_top.webp") 1x);
  background-image: image-set(url("../images/ic_to_top.png") 1x);
  /* Safari */
  background-position: center center;
  background-size: 15px 24px;
  background-repeat: no-repeat;
}

.map_open .to_top {
  display: none;
}

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #e9f0f4;
  width: 5px;
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #cad3d9 !important;
}

.selectize-dropdown {
  padding: 0px 10px 10px 0;
}

.more_container .more_item {
  display: none;
}

.hidden {
  display: none;
}

.moskva-btn {
  border-radius: 10px;
  background: #6140e5;
  color: #fff700;
  font-weight: 700;
  font-size: 20px;
  font-family: inherit;
  padding: 20px;
  transition: 150ms;
}

.moskva-btn:hover {
  cursor: pointer;
  transform: scale(1.05);
  text-decoration: none;
  color: #fff;
}

.moskva-input {
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-family: inherit;
}

.js-feedback {
  position: relative;
}

.js-feedback::before {
  transition: 400ms;
  content: '';
  position: absolute;
  backdrop-filter: saturate(0%) blur(0px);
}

.js-feedback__success::before {
  text-align: center;
  content: 'Спасибо, скоро мы вам перезвоним';
  position: absolute;
  left: 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
  top: 0;
  width: 100%;
  text-shadow: 0 5px 25px #fff;
  display: flex;
  vertical-align: middle;
  height: 100%;
  background: #ff000000;
  backdrop-filter: saturate(83%) blur(14px);
  justify-content: center;
  align-items: center;
}

.js-feedback__success .moskva-btn:hover {
  transform: none;
}

header {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1180px) {
  header {
    position: sticky;
    top: 0;
    z-index: 5;
    margin-bottom: 40px;
    box-shadow: 0 11px 41px 0 #9d87ff;
  }
}

header .top {
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  background-color: #fdfdfe;
  padding: 8px 0;
  color: #bcbbbd;
  font-size: 12px;
}

header .top:after {
  content: "";
  display: table;
  clear: both;
}

header .top a {
  color: #08b7d7;
}

header .top .close {
  display: block;
  width: 11px;
  height: 11px;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: initial;
  background-image: url("../images/ic_close.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_close.webp") 1x);
  background-image: image-set(url("../images/ic_close.png") 1x);
  /* Safari */
}

@media only screen and (max-width: 1180px) {
  header .top {
    font-size: 0px;
  }
  header .top .cont {
    display: flex;
    justify-content: center;
  }
  header .top .left, header .top .right {
    float: none;
  }
  header .top span {
    font-size: 12px;
    margin-right: 10px;
  }
}

.header__info {
  padding: 20px 0;
  display: grid;
  grid: 'logo phone social'/ 1fr auto auto;
  align-items: center;
}

.header__info:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 1180px) {
  .header__info {
    justify-content: space-between;
    padding: 15px 15px 15px;
    margin-bottom: 0;
    grid: 'menu logo phone' 'social social social';
  }
}

.header__info-phone, .header__info-social {
  justify-content: right;
}

.header__info .cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__info .col {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1180px) {
  .header__info .logo img {
    max-width: 150px;
  }
}

.header__info .phone {
  margin-left: 20px;
}

.header__info .phone_cta {
  color: #000;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 1.3;
  top: 3px;
  position: relative;
  margin-left: 50px;
}

.header__info .phone_cta:hover {
  color: inherit;
}

.header__info .phone a {
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  padding: 12px 25px 10px;
  border-radius: 23px;
  color: #fff;
  font-size: 18px;
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .header__info .phone a {
    display: block;
    border-radius: 5px;
    border: 2px solid #fff;
    padding: 7px;
    font-size: 0;
    background-color: initial;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../images/ic_phone.webp");
    /* Firefox */
    background-image: -webkit-image-set(url("../images/ic_phone.webp") 1x);
    background-image: image-set(url("../images/ic_phone.png") 1x);
    /* Safari */
    width: 18px;
    height: 18px;
  }
}

@media only screen and (max-width: 1180px) {
  .header__info .phone {
    margin-left: 0;
  }
}

.header__info.inner {
  background-color: #523fa4;
}

.header__info.inner .phone_cta {
  color: #fff;
}

@media only screen and (max-width: 1180px) {
  .header__info.inner .phone_cta {
    display: none;
  }
}

.header__info.inner .menu a {
  color: #fff;
}

.header__info.inner .menu a:hover {
  color: #08b7d7;
  text-decoration: none;
}

.header__info.inner .logo .desktop {
  display: none !important;
}

.header__info.inner .logo .mobile {
  display: block !important;
}

@media only screen and (max-width: 1180px) {
  .header__info {
    background-color: #523fa4;
  }
}

.header__info-phone {
  grid-area: phone;
}

.col.header__info-social {
  grid-area: social;
}

@media only screen and (max-width: 1180px) {
  .col.header__info-social {
    display: none;
    background: #38287e;
    border-radius: 10px;
    padding: 10px 10px 10px 15px;
    margin-top: 15px;
  }
}

.col.header__info-social_opened {
  display: flex;
  justify-content: space-between;
}

.header__info-logo {
  grid-area: logo;
}

@media only screen and (max-width: 1180px) {
  .header__info-logo {
    justify-self: center;
  }
}

.header__info-menu {
  grid-area: menu;
}

.menu.up .menu_item ul {
  bottom: 20px;
}

.menu.up .menu_item ul ul {
  bottom: 0;
  top: unset;
}

.menu {
  display: flex;
}

.menu a {
  color: #000;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  padding: 0 20px;
}

.menu a:hover {
  color: #08b7d7;
  text-decoration: none;
}

@media only screen and (max-width: 1180px) {
  .menu {
    display: none;
  }
}

.menu_item {
  position: relative;
}

.menu_item:hover > ul {
  display: block;
}

.menu_item ul {
  display: none;
  position: absolute;
  width: max-content;
  left: -50%;
  padding: 10px 0;
  z-index: 2;
}

.menu_item ul ul {
  left: 100%;
  top: 0;
  padding-top: 0;
  max-width: 250px;
  max-height: 300px;
  overflow-y: scroll;
}

.menu_item ul li {
  background-color: #523ba5;
  position: relative;
  padding: 10px 30px;
}

.menu_item ul li:hover > ul {
  display: block;
}

.menu_item ul li:first-child {
  padding-top: 20px;
}

.menu_item ul li:last-child {
  padding-bottom: 20px;
}

.menu_item ul a {
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-transform: inherit;
  padding: 0;
}

.header__social-item {
  max-height: 30px;
  max-width: 30px;
  transition: 150ms;
}

.header__social-item:hover {
  transform: scale(1.2);
}

.header__social-img {
  height: 100%;
}

@media only screen and (max-width: 1180px) {
  .header__social-img {
    filter: invert(1);
  }
}

.header__social {
  display: flex;
  margin-left: 20px;
  gap: 15px;
}

@media only screen and (max-width: 1180px) {
  .header__social {
    margin-left: 0;
  }
}

.header__nav,
.header__menu {
  display: flex;
  justify-content: space-between;
}

.header__nav {
  background: #f5f7ff;
  border-radius: 50px;
  margin-bottom: 20px;
  height: 50px;
  padding: 5px;
  gap: 5px;
  position: relative;
}

@media only screen and (max-width: 1180px) {
  .header__nav {
    background-color: #523fa4;
    display: none;
    padding: 0;
    border-radius: 0;
    height: auto;
    margin-bottom: 0;
  }
}

.header__arrow {
  content: '';
  background-image: url("../images/arrow-up.png");
  width: 120px;
  height: 31px;
  position: absolute;
  bottom: -31px;
  left: 0;
  right: 0;
  margin: auto;
  display: none;
}

@media only screen and (max-width: 1180px) {
  .header__arrow {
    display: block;
  }
}

.header__arrow_hidden {
  background-image: url("../images/arrow-down.png");
}

.header__menu {
  gap: 5px;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

@media only screen and (max-width: 1180px) {
  .header__menu {
    display: none;
  }
}

.header__menu-item {
  font-weight: 500;
  display: flex;
  line-height: 20px;
  cursor: pointer;
  background: #fff;
  padding: 10px 40px;
  border-radius: 5px;
  width: 100%;
  color: black;
  text-align: center;
  justify-content: center;
}

.header__menu-item:first-child {
  border-radius: 20px 5px 5px 20px;
}

.header__menu-item:hover {
  text-decoration: none;
  color: #08b7d7;
}

.header__search {
  position: relative;
  width: 400px;
}

@media only screen and (max-width: 1180px) {
  .header__search {
    width: auto;
    padding: 0 15px 15px;
  }
}

.header__search::before {
  content: '';
  background: url("../images/magnifier.png");
  width: 20px;
  height: 20px;
  background-size: contain;
  display: block;
  position: absolute;
  left: 11px;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0.2;
}

@media only screen and (max-width: 1180px) {
  .header__search::before {
    left: 30px;
    top: 11px;
    margin: unset;
  }
}

.ya-site-form__input-text {
  height: 25px;
  border-radius: 5px;
  padding: 19px 10px 19px 38px;
  width: 290px !important;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  border: 5px solid #e8e8f6;
}

@media only screen and (max-width: 1180px) {
  .ya-site-form__input-text {
    box-sizing: border-box;
    width: calc(100vw - 103px) !important;
    padding: 20px 10px 20px 50px;
    border: none;
    border-radius: 10px;
    text-transform: uppercase;
  }
}

#ya-site-form0 .ya-site-form__wrap,
#ya-site-form0 .ya-site-form__search,
#ya-site-form0 .ya-site-form__search-input-layout {
  width: auto !important;
}

#ya-site-form0 .ya-site-form__search-input {
  padding: 0 !important;
}

.ya-site-form__submit {
  border-radius: 5px 20px 20px 5px;
  background: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  border: none;
  padding: 13px 15px;
}

@media only screen and (max-width: 1180px) {
  .ya-site-form__submit {
    padding: 14px 15px;
    border-radius: 10px;
  }
}

.ya-site-form__submit:hover {
  background: #f9f9f9;
  cursor: pointer;
}

@media only screen and (max-width: 1180px) {
  #ya-site-form0 .ya-site-form__submit {
    margin-left: 10px !important;
  }
}

.main {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 40px;
}

@media only screen and (max-width: 1180px) {
  .main {
    gap: 0;
    display: block;
  }
}

@media only screen and (max-width: 1180px) {
  .main .cont {
    padding: 0;
  }
}

.main .cta {
  grid-template-columns: 1.4fr 1fr;
  margin-bottom: 30px;
  background-color: initial;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: url("../images/bg_cta.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/bg_cta.webp") 1x);
  background-image: image-set(url("../images/bg_cta.png") 1x);
  /* Safari */
  background-size: cover;
  border-radius: 20px;
  display: grid;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  padding: 30px;
}

@media only screen and (max-width: 1180px) {
  .main .cta {
    padding: 30px;
    background-position: right -140px top 0px;
    display: block;
  }
}

.main .cta_title {
  max-width: 500px;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 600;
  font-size: 39px;
  line-height: 48px;
  margin-bottom: 60px;
}

.main .cta_title span {
  color: #ffd737;
  font-family: Panton, Arial, sans-serif;
  font-weight: 700;
}

@media only screen and (max-width: 1180px) {
  .main .cta_title {
    font-size: 25px;
    line-height: 30px;
  }
}

.main .cta_phone {
  display: flex;
  flex-direction: column;
}

.main .cta_phone span {
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.main .cta_phone a {
  padding: 24px 70px 16px;
  color: #2d256a;
  font-family: Panton, Arial, sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 28.06px;
  border-radius: 38px;
  background-color: #1dda6d;
}

@media only screen and (max-width: 1180px) {
  .main .cta_phone a {
    font-size: 20px;
    line-height: 18.83px;
    padding: 15px 40px;
  }
}

@media only screen and (max-width: 360px) {
  .main .cta_phone a {
    padding: 15px;
  }
}

.main .cta_text {
  max-width: 500px;
  margin: 75px 0 0 0;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-family: Panton;
  font-size: 16px;
  line-height: 24px;
}

.main__video {
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  margin-bottom: 30px;
}

.main__video iframe {
  width: 100%;
  height: 472px;
}

@media only screen and (max-width: 1180px) {
  .main__video iframe {
    width: calc(100vw - 48px);
    height: calc((100vw - 48px) * 9 / 16);
  }
}

.about {
  margin-bottom: 40px;
}

@media only screen and (max-width: 1180px) {
  .about {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1180px) {
  .about .content .price, .about .content .text_block {
    display: none !important;
  }
  .about .content.left {
    text-align: center;
  }
  .about .content.left .title_block {
    margin-bottom: 0;
  }
}

.about .content .price {
  display: inline-block;
  background-color: #f1f7f8;
  border-radius: 20px;
  padding: 10px 30px;
  color: #010101;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
}

@media only screen and (max-width: 1180px) {
  .about .left {
    margin-bottom: 20px;
  }
}

.main + .about {
  background-color: #fafafb;
  margin: -50px 0 0 0;
  padding: 84px 0 37px 0;
}

@media only screen and (max-width: 1180px) {
  .main + .about {
    margin: 0;
    padding: 40px 0 25px;
  }
  .main + .about .cont {
    display: block;
  }
}

.filter {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1180px) {
  .filter {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 1180px) {
  .filter + .centers {
    margin-top: -15px;
  }
}

.filter .select_line {
  position: relative;
  background-color: #08b7d7;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1180px) {
  .filter .select_line {
    flex-direction: column;
    padding: 30px 50px;
    margin: 0;
  }
}

@media only screen and (max-width: 360px) {
  .filter .select_line {
    padding: 30px 20px;
  }
}

.filter .select_line .selectize-control {
  margin-right: 21px;
}

@media only screen and (max-width: 1180px) {
  .filter .select_line .selectize-control {
    margin: 0 0 10px;
  }
}

.filter .select_line .selectize-control.service_or_spec:before {
  content: "";
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/ic_serv.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_serv.webp") 1x);
  background-image: image-set(url("../images/ic_serv.png") 1x);
  /* Safari */
  width: 17px;
  height: 14px;
  position: absolute;
  top: 18px;
  left: 31px;
  z-index: 2;
}

@media only screen and (max-width: 1180px) {
  .filter .select_line .selectize-control.service_or_spec:before {
    top: 16px;
  }
}

.filter .select_line .selectize-control.metro_or_dep:before {
  content: "";
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/ic_department.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_department.webp") 1x);
  background-image: image-set(url("../images/ic_department.png") 1x);
  /* Safari */
  width: 14px;
  height: 16px;
  position: absolute;
  top: 17px;
  left: 30px;
  z-index: 2;
}

.filter .select_line .selectize-input {
  position: relative;
  width: 420px;
  box-sizing: border-box;
  padding-left: 54px;
  padding-right: 40px;
}

@media only screen and (max-width: 1180px) {
  .filter .select_line .selectize-input {
    width: 100%;
  }
}

.filter .select_line .selectize-dropdown {
  overflow: hidden;
  border-radius: 0 0 25px 25px;
}

.filter .select_line button[type="submit"] {
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  border-radius: 25px;
  background-color: #523fa4;
  padding: 12px 91px;
}

@media only screen and (max-width: 1180px) {
  .filter .select_line button[type="submit"] {
    padding: 12px 15px;
  }
}

@media only screen and (max-width: 1180px) {
  .filter .sort_links {
    display: none;
  }
}

.filter .sort_links .name {
  color: #333333;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
}

.filter .sort_links .sort {
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid #f1f2f7;
  background-color: #fff;
  padding: 7px 10px;
  color: #999899;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-left: 10px;
}

.filter .sort_links .sort:hover {
  text-decoration: none;
}

.filter .sort_links .sort.active {
  background-color: #08b7d7;
  color: #fff;
}

.filter .sort_links .sort.active.asc:before, .filter .sort_links .sort.active.desc:before {
  background-position: -8px 0;
}

.filter .sort_links .sort.asc, .filter .sort_links .sort.desc {
  position: relative;
  padding-left: 20px;
}

.filter .sort_links .sort.asc:before, .filter .sort_links .sort.desc:before {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  background-color: initial;
  background-position: 0 0;
  background-image: url("../images/ic_arrow.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_arrow.webp") 1x);
  background-image: image-set(url("../images/ic_arrow.png") 1x);
  /* Safari */
  width: 8px;
  height: 13px;
  top: 8px;
  left: 10px;
  transform: rotate(180deg);
}

.filter .sort_links .sort.desc:before {
  transform: rotate(0deg);
}

.selectize-input {
  border-radius: 25px;
  background-color: #fff;
  border: none;
  padding: 16px 10px 16px 10px;
  white-space: nowrap;
}

@media only screen and (max-width: 1180px) {
  .selectize-input {
    padding: 12px 10px;
  }
}

.selectize-input input, .selectize-input div {
  color: #000;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  vertical-align: sub;
  line-height: 16px;
}

.selectize-input.dropdown-active {
  box-shadow: none;
  border-radius: 25px 25px 0 0;
}

.selectize-input > * {
  width: calc(100% - 12px);
  overflow: hidden;
}

.selectize-dropdown {
  border: none;
  border-top: 1px solid rgba(207, 217, 222, 0.8);
}

.selectize-dropdown-content {
  color: #000;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  padding-bottom: 10px;
}

.selectize-dropdown-content .optgroup-header {
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  padding-left: 20px;
}

.selectize-dropdown-content .option {
  padding-left: 30px;
}

.selectize-dropdown-content .option a {
  color: #08b7d7;
}

.selectize-dropdown-content .option.active {
  background-color: #f1f7f8;
  color: #000;
}

@media only screen and (max-width: 1180px) {
  aside {
    min-height: auto !important;
  }
}

aside #map {
  top: 0;
  position: sticky;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  box-shadow: 0 1px 12px #e0e7e7;
  border-radius: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 1180px) {
  aside #map {
    display: none;
  }
}

aside #map.is-active {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 5;
  border-radius: 0;
}

.aside {
  width: 300px;
  position: sticky;
  top: 40px;
  align-self: flex-start;
  overflow: auto;
  max-height: calc(100vh - 80px);
  margin-bottom: 40px;
}

.aside::-webkit-scrollbar {
  width: 10px;
}

.aside::-webkit-scrollbar-track {
  background-color: #dae2ff;
  border-radius: 50px;
}

.aside::-webkit-scrollbar-thumb {
  background: #627bd5;
  border-radius: 50px;
}

@media only screen and (max-width: 1180px) {
  .aside {
    left: -85vw;
    top: 66px;
    z-index: 10;
    width: 100vw;
    max-height: calc(100vh);
    height: calc(100vh - 66px);
    margin: 0;
    overflow: hidden;
    font-size: 18px;
    position: fixed;
    transition: 200ms;
  }
}

.aside_opened {
  left: 0;
}

.aside_filtered {
  background: #0000008a;
  backdrop-filter: blur(10px);
}

.aside__menu {
  background: #f5f7ff;
  border-radius: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 1180px) {
  .aside__menu {
    border-radius: 0;
    width: 85vw;
    height: calc(100% - 40px);
    padding: 40px 0 0;
    overflow: auto;
    top: 0;
    position: relative;
  }
}

.aside__child-list {
  display: none;
}

.centers {
  margin-bottom: 50px;
}

.centers .cont:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 1180px) {
  .centers .cont {
    padding: 0;
  }
}

.centers .more_container .pagination {
  display: none !important;
}

.centers .more_container .more_item {
  display: block !important;
}

.centers .list .center_item:last-child {
  margin-bottom: 0;
}

.centers .center_item {
  margin-bottom: 15px;
  box-shadow: 0 1px 12px #e0e7e7;
  border-radius: 20px;
  background-color: #fff;
  padding: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item {
    border-radius: 0;
    padding: 15px 40px;
  }
}

@media only screen and (max-width: 360px) {
  .centers .center_item {
    padding: 15px 20px;
  }
}

.centers .center_item .top {
  display: flex;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .top {
    margin-bottom: 10px;
  }
}

.centers .center_item .top .col:first-child {
  margin-right: 15px;
}

.centers .center_item .top .col:last-child {
  width: 100%;
}

.centers .center_item .top .logo {
  display: block;
  border-radius: 20px;
  border: 1px solid #f1f2f7;
  background-color: #fff;
  padding: 15px 0;
  margin-bottom: 10px;
  max-width: 110px;
  max-height: 61px;
  overflow: hidden;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .top .logo {
    padding: 10px 0;
    margin: 0;
  }
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .top .logo img {
    max-width: 70px;
    max-height: 48px;
  }
}

.centers .center_item .top .title {
  margin: 10px 0;
  display: block;
  color: #08b7d7;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .top .rating {
    display: flex;
    align-items: center;
  }
  .centers .center_item .top .rating .stars {
    margin-right: 10px;
  }
}

.centers .center_item .price_list {
  color: #c0cdcf;
  font-family: Panton, Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .price_list {
    margin-bottom: 10px;
    font-size: 13px;
  }
}

.centers .center_item .price_list li {
  position: relative;
}

.centers .center_item .price_list li:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .price_list li {
    margin-bottom: 10px;
  }
}

.centers .center_item .price_list li:hover a,
.centers .center_item .price_list li:hover span:not(.old_price) {
  color: #08b7d7;
}

.centers .center_item .price_list li:before {
  content: '';
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px dotted #c0cdcf;
}

.centers .center_item .price_list li:last-child:before {
  bottom: 5px;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .price_list li:last-child .right {
    width: 100%;
    float: none;
    padding: 0;
    text-align: left;
  }
}

.centers .center_item .price_list .left {
  padding-right: 5px;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .price_list .left {
    width: calc(60% - 5px);
  }
}

.centers .center_item .price_list .right {
  padding-left: 5px;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .price_list .right {
    width: calc(40% - 5px);
    text-align: right;
  }
}

.centers .center_item .price_list .left,
.centers .center_item .price_list .right {
  background-color: #fff;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.centers .center_item .price_list .name {
  color: #000;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  line-height: 25px;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .price_list .name {
    line-height: 15px;
  }
}

.centers .center_item .price_list .price {
  color: #000;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  line-height: 25px;
}

.centers .center_item .price_list .old_price {
  color: #999899;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  text-decoration: line-through;
  margin-right: 5px;
}

.centers .center_item .price_list .right a {
  color: #08b7d7;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.centers .center_item .address {
  border-radius: 20px;
  background-color: #f1f7f8;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .address {
    background: none;
    margin: 0 -40px;
    padding: 15px 40px 0;
    border-top: 1px solid #edf2f3;
    border-radius: 0;
  }
}

.centers .center_item .address .item {
  display: inline-block;
  margin: 10px 5px 10px 15px;
  color: #888c8c;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  text-decoration: none;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .address .item {
    margin: 0;
  }
  .centers .center_item .address .item:not(:first-child) {
    display: none;
  }
}

.centers .center_item .address a.item {
  color: #08b7d7;
}

.centers .center_item .address .street,
.centers .center_item .address .metro {
  position: relative;
}

.centers .center_item .address .street:before,
.centers .center_item .address .metro:before {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/ic_department.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_department.webp") 1x);
  background-image: image-set(url("../images/ic_department.png") 1x);
  /* Safari */
  width: 14px;
  height: 16px;
  left: 0;
  top: 2px;
}

.centers .center_item .address .street {
  padding-left: 21px;
}

.centers .center_item .address .metro {
  padding-left: 26px;
}

.centers .center_item .address .metro:before {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/ic_metro.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_metro.webp") 1x);
  background-image: image-set(url("../images/ic_metro.png") 1x);
  /* Safari */
  width: 21px;
  height: 15px;
  top: 2px;
}

.centers .center_item .address .metro:hover {
  color: #523fa4;
}

.centers .center_item .address .metro.active {
  color: #523fa4;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 500;
}

.centers .center_item .address .metro.active:before {
  background-image: url("../images/ic_metro_a.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_metro_a.webp") 1x);
  background-image: image-set(url("../images/ic_metro_a.png") 1x);
  /* Safari */
}

.centers .center_item .address + .stickers {
  margin-top: 10px;
}

.centers .center_item .stickers {
  display: none;
}

.centers .center_item .stickers .item {
  display: none;
  border-radius: 15px;
  border: 1px solid #f1f2f7;
  background-color: #08b7d7;
  color: #fff;
  padding: 7px 10px;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-right: 5px;
}

.centers .center_item .bottom {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.centers .center_item .bottom .total {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/ic_notify.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_notify.webp") 1x);
  background-image: image-set(url("../images/ic_notify.png") 1x);
  /* Safari */
  padding-left: 32px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  color: #9ba0a0;
  font-size: 13px;
  line-height: 14px;
}

.centers .center_item .bottom .total b {
  color: #08b7d7;
  font-size: 15px;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .bottom .total {
    display: none;
  }
}

.centers .center_item .bottom .phone {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/ic_phone_purple.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_phone_purple.webp") 1x);
  background-image: image-set(url("../images/ic_phone_purple.png") 1x);
  /* Safari */
  padding-left: 32px;
}

.centers .center_item .bottom .phone.not_partner {
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  max-width: 220px;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .bottom .phone.not_partner {
    max-width: inherit;
    font-size: 13px;
    line-height: 18px;
  }
}

.centers .center_item .bottom .phone.not_partner a {
  margin-top: 0;
  font-size: 14px;
  color: #999899;
}

.centers .center_item .bottom .phone a {
  display: block;
  margin-top: 5px;
  color: #523fa4;
  font-family: Panton, Arial, sans-serif;
  font-weight: 800;
  font-size: 20px;
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .bottom .phone a {
    margin-top: 25px;
    display: inline-block;
    padding: 12px 30px 10px;
    border-radius: 25px;
    background-color: #523fa4;
    color: #fff;
    font-family: Panton, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: Panton, Arial, sans-serif;
    font-weight: 500;
  }
}

@media only screen and (max-width: 1180px) {
  .centers .center_item .bottom .phone {
    width: 100%;
    display: block;
    background: none;
    padding: 0;
  }
}

.centers .buttons {
  position: sticky;
  bottom: 20px;
  z-index: 1;
  width: 100%;
}

@media only screen and (max-width: 1180px) {
  .centers .buttons {
    margin-top: 20px;
  }
}

.centers .buttons .cont {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 1180px) {
  .centers .buttons .cont {
    padding: 0 40px;
  }
}

.map_open .centers .buttons {
  z-index: 6;
  position: fixed;
}

.map_open .centers .buttons .centers_on_map {
  margin: 0;
  width: 100%;
  text-align: center;
}

.map_open .centers .buttons .centers_on_map + div {
  display: none;
}

.centers .buttons .centers_on_map {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
  margin-top: 0;
  background-color: #08b7d7;
  color: #fff;
  margin-right: 20px;
  display: none;
}

@media only screen and (max-width: 1180px) {
  .centers .buttons .centers_on_map {
    display: block;
    padding: 10px 15px;
    width: 100%;
    text-align: center;
  }
}

.centers .buttons .to_top {
  position: relative;
  top: -45px;
  right: -25px;
}

@media only screen and (max-width: 1180px) {
  .centers .buttons .to_top {
    display: block;
    top: auto;
    right: auto;
  }
}

.centers .hours24 .stickers {
  display: block;
}

.centers .hours24 .stickers .hours24 {
  display: inline-block;
}

.centers .det .stickers {
  display: block;
}

.centers .det .stickers .det {
  display: inline-block;
}

.centers .akcii .stickers {
  display: block;
}

.centers .akcii .stickers .akcii {
  display: inline-block;
}

.centers .skontrastom .stickers {
  display: block;
}

.centers .skontrastom .stickers .skontrastom {
  display: inline-block;
}

.advantages {
  margin-bottom: 50px;
  background-color: #523fa4;
  padding: 50px 0;
  color: #fff;
  text-align: center;
}

.advantages .grid {
  padding: 0 50px;
  justify-content: space-between;
}

@media only screen and (max-width: 1180px) {
  .advantages .grid {
    flex-direction: column;
    padding: 0 20px;
  }
}

.advantages .title_block {
  margin-bottom: 60px;
  color: #fff;
}

.advantages .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media only screen and (max-width: 1180px) {
  .advantages .item {
    justify-content: start;
    align-items: center;
    flex-direction: row;
    min-height: 86px;
  }
  .advantages .item:not(:last-child) {
    margin-bottom: 30px;
  }
}

.advantages .item div {
  display: flex;
  align-items: center;
  justify-content: center;
  content: '';
  width: 127px;
  height: 127px;
  background-color: #5945ac;
  border-radius: 50%;
}

@media only screen and (max-width: 1180px) {
  .advantages .item div {
    width: 86px;
    height: 86px;
    margin-right: 15px;
  }
}

.advantages .item img {
  display: block;
  position: relative;
  z-index: 1;
  top: -20px;
}

@media only screen and (max-width: 1180px) {
  .advantages .item img {
    top: 0;
  }
}

.advantages .item span {
  display: block;
  margin-top: 5px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  z-index: 1;
  top: -55px;
}

@media only screen and (max-width: 1180px) {
  .advantages .item span {
    text-align: left;
    top: 0;
  }
}

.advantages .link {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
  border-radius: 25px;
  background-color: #08b7d7;
  color: #fff;
  margin-top: 0;
}

@media only screen and (max-width: 1180px) {
  .advantages .link {
    margin-top: 60px;
    width: 100%;
    padding: 12px 0 10px;
  }
}

.articles {
  margin-bottom: 50px;
}

@media only screen and (min-width: 1180px) {
  .info_grid .articles {
    height: calc(100% + 40px);
    display: flex;
    flex-direction: column;
  }
}

.info_grid .articles .link {
  margin-top: auto;
  max-width: 200px;
}

@media only screen and (max-width: 1180px) {
  .info_grid .articles .link {
    margin-top: 20px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 1180px) {
  .articles + .pagination {
    margin-bottom: 40px;
  }
}

.articles .item {
  display: flex;
}

.articles .item img {
  border-radius: 10px;
}

.articles .item .col:first-child {
  margin-right: 10px;
}

@media only screen and (max-width: 1180px) {
  .articles .item .col:first-child {
    float: left;
  }
}

.articles .link {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
  margin-top: 20px;
}

@media only screen and (max-width: 1180px) {
  .articles .link {
    width: 100%;
    text-align: center;
  }
}

.articles_sections {
  margin-bottom: 40px;
}

.articles_sections .list {
  box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
  border-radius: 20px;
  background-color: #fff;
}

.articles_sections .list .item {
  position: relative;
  padding: 10px 30px;
}

.articles_sections .list .item:nth-child(2n) {
  background-color: #f1f7f8;
}

.articles_sections .list .item .title {
  color: #08b7d7;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.articles_sections .list .item:after {
  content: '';
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/ic_arrows.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_arrows.webp") 1x);
  background-image: image-set(url("../images/ic_arrows.png") 1x);
  /* Safari */
  position: absolute;
  top: calc(50% - 6px);
  right: 30px;
  width: 12px;
  height: 12px;
}

.articles + .articles_sections {
  margin-top: 65px;
}

@media only screen and (max-width: 1180px) {
  .articles + .articles_sections {
    margin-top: 25px;
    margin-bottom: 0;
  }
}

.banner + .articles, .banner + .articles_sections {
  margin-top: 30px;
}

.articles_content {
  margin-bottom: 50px;
}

@media only screen and (max-width: 1180px) {
  .articles_content {
    margin: -10px 0 15px;
  }
}

@media only screen and (max-width: 1180px) {
  .articles_content .title_block {
    display: none;
  }
}

.articles_content .list a {
  position: relative;
  display: block;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  color: #08b7d7;
  font-size: 13px;
  line-height: 24px;
  margin: 20px 0;
  padding-left: 20px;
}

.articles_content .list a:last-child {
  margin-bottom: 0;
}

.articles_content .list a:after {
  content: '';
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/ic_arrows.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_arrows.webp") 1x);
  background-image: image-set(url("../images/ic_arrows.png") 1x);
  /* Safari */
  position: absolute;
  top: calc(50% - 6px);
  left: 0;
  width: 12px;
  height: 12px;
}

.articles .slick-slider {
  padding-bottom: 30px;
}

.articles .slick-slider .item {
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .articles .slick-slider .item {
    display: block;
  }
}

.articles .slick-slider .slick-dots li {
  margin-right: 7px;
  margin-top: 7px;
  display: inline-block;
}

.articles .slick-slider .slick-dots li button {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: transparent;
  background-image: url("../images/ic_dots.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_dots.webp") 1x);
  background-image: image-set(url("../images/ic_dots.png") 1x);
  /* Safari */
  width: 15px;
  height: 14px;
  text-indent: -99999px;
}

.articles .slick-slider .slick-dots li.slick-active button {
  background-position: -22px;
}

.articles__item {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.articles__item-pic,
.articles__item-img {
  width: 240px;
  display: flex;
}

.articles__item-name {
  display: block;
  color: #08b7d7;
  font-size: 16px;
  margin-bottom: 6px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
}

.articles__item-name:hover {
  color: #523fa4;
}

.articles__item-desc {
  display: block;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.articles__item-read {
  display: inline-block;
  border-radius: 15px;
  background-color: #08b7d7;
  color: #fff;
  padding: 2px 15px;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
}

.cats_wall {
  margin-bottom: 50px;
}

.cats_wall .grid {
  justify-content: space-between;
  flex-direction: column;
}

.cats_wall .item {
  width: 100%;
}

@media only screen and (max-width: 1180px) {
  .cats_wall .item {
    width: 100%;
  }
  .cats_wall .item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.cats_wall .item .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #08b7d7;
}

.cats_wall .item .top .title {
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.cats_wall .item .top .link {
  color: #fff;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  padding-right: 17px;
}

.cats_wall .item .top .link:after {
  content: '';
  background-repeat: no-repeat;
  background-position: -12px 0;
  background-color: initial;
  background-image: url("../images/ic_arrows.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_arrows.webp") 1x);
  background-image: image-set(url("../images/ic_arrows.png") 1x);
  /* Safari */
  position: absolute;
  top: 6px;
  right: 0;
  width: 12px;
  height: 12px;
}

.cats_wall .item .body {
  padding: 10px 0;
  box-sizing: border-box;
}

.cats_wall .item .body .list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

@media only screen and (max-width: 1180px) {
  .cats_wall .item .body .list {
    flex-direction: column;
    padding-bottom: 0;
  }
}

.cats_wall .item .body .list li {
  width: 33.3%;
  box-sizing: border-box;
  position: relative;
}

@media only screen and (max-width: 1180px) {
  .cats_wall .item .body .list li {
    width: 100%;
    padding: 0 !important;
  }
}

.cats_wall .item .body .list li:nth-child(3n+1) {
  padding-right: 40px;
}

.cats_wall .item .body .list li:nth-child(3n+2) {
  padding-right: 20px;
  padding-left: 20px;
}

.cats_wall .item .body .list li:nth-child(3n+3) {
  padding-left: 40px;
}

.cats_wall .item .body .list .name {
  color: #08b7d7;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;
}

.cats_wall .item .body .list .sub {
  padding-bottom: 20px;
}

.cats_wall .item .body .list .sub_item {
  display: block;
  width: 100%;
  color: #000;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  position: relative;
}

.cats_wall .item .body .list .sub_item:not(:last-child) {
  margin-bottom: 10px;
}

.cats_wall .item .body .list .sub_item:before {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px dotted #c0cdcf;
}

.cats_wall .item .body .list .sub_content {
  display: flex;
  justify-content: space-between;
}

.cats_wall .item .body .list .sub .service {
  background-color: #fafafb;
  position: relative;
  padding-right: 5px;
  max-width: 60%;
}

.cats_wall .item .body .list .sub .price {
  padding-left: 5px;
  position: relative;
  background-color: #fafafb;
  align-self: flex-end;
}

.cats_wall .item .body .list .sub .btn_more {
  display: inline-block;
  margin-top: 5px;
  padding: 7px 20px;
  border-radius: 20px;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 300;
  border: 1px solid #08b7d7;
  color: #08b7d7;
  font-size: 13px;
}

footer {
  height: 308px;
  box-sizing: border-box;
  position: relative;
}

footer:before {
  content: '';
  position: absolute;
  background-color: #08b7d7;
  width: 100%;
  height: 5px;
  left: 0;
  top: -5px;
}

footer .info {
  display: none;
  padding: 35px 0 30px 0;
  background-color: #3c2d7d;
  margin-bottom: 0;
}

footer .info:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 1180px) {
  footer .info {
    display: block;
    padding: 25px 0 20px 0;
  }
}

footer .info .cont {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1180px) {
  footer .info .cont {
    flex-direction: column;
  }
}

footer .info .col:first-child {
  max-width: 300px;
}

@media only screen and (max-width: 1180px) {
  footer .info .col:first-child {
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}

footer .info .col:last-child {
  display: flex;
}

@media only screen and (max-width: 1180px) {
  footer .info .col:last-child {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
}

footer .info .col:last-child nav:first-of-type {
  margin-right: 200px;
}

@media only screen and (max-width: 1180px) {
  footer .info .col:last-child nav:first-of-type {
    margin-right: 0;
  }
}

footer .info .col:last-child nav:first-of-type .title {
  margin: 26px 0 13px;
}

footer .info .col:last-child nav:first-of-type .title:first-of-type {
  margin-top: 0;
}

footer .info .desc {
  margin-top: 15px;
  color: #fff;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

@media only screen and (max-width: 1180px) {
  footer .info .desc {
    display: none;
  }
}

footer .info .phone {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
  border-radius: 25px;
  background-color: #08b7d7;
  color: #fff;
  margin-top: 30px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 600;
}

@media only screen and (max-width: 1180px) {
  footer .info .phone {
    width: 100%;
  }
}

footer .info nav a, footer .info nav a.title {
  display: block;
  color: #08b7d7;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

footer .info nav a:not(:last-child), footer .info nav a.title:not(:last-child) {
  margin-bottom: 24px;
}

footer .bottom {
  background-color: #221752;
  padding: 25px 0;
}

footer .bottom:after {
  content: "";
  display: table;
  clear: both;
}

footer .bottom .footer__link,
footer .bottom .menu_item > a {
  color: #6757aa;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  text-transform: uppercase;
}

footer .bottom .footer__link:hover,
footer .bottom .menu_item > a:hover {
  color: #fff;
}

footer .bottom .menu {
  margin-right: 20px;
}

footer .bottom .left {
  color: #503f9b;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
}

@media only screen and (max-width: 1180px) {
  footer .bottom .left {
    float: none;
    text-align: center;
  }
}

footer .bottom .right {
  display: flex;
  line-height: 24px;
}

@media only screen and (max-width: 1180px) {
  footer .bottom .right {
    display: none;
  }
}

.footer {
  color: #fff;
  padding-top: 75px;
  background-color: #2d256a;
  grid-area: footer;
}

.footer__top {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 35px;
}

@media only screen and (max-width: 1180px) {
  .footer__top {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1180px) {
  .footer__top {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 1180px) {
  .footer__top {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}

.footer__mobile-list {
  display: none;
}

@media only screen and (max-width: 1180px) {
  .footer__mobile-list {
    display: flex;
    justify-content: center;
    grid-gap: 10%;
    margin-bottom: 20px;
  }
}

.footer__info {
  text-align: center;
  font-size: 14px;
}

@media only screen and (max-width: 1180px) {
  .footer__info {
    grid-column: 1/5;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1180px) {
  .footer__info {
    grid-column: 1/3;
  }
}

.footer__logo:before {
  content: '';
  background: url(../images/logo_mini.png) 50% no-repeat;
  background-size: 55px 55px;
  height: 55px;
  margin-bottom: 13px;
  display: inline-block;
  width: 55px;
}

.footer__logo-title {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1180px) {
  .footer__logo-title {
    font-size: 14px;
  }
}

.footer__text {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 600;
}

.footer__phone {
  font-size: 23px;
  font-weight: 700;
  color: #fff700;
}

@media only screen and (max-width: 1180px) {
  .footer__phone {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1180px) {
  .footer__list {
    display: none;
  }
  .footer__list:last-child {
    display: block;
  }
  .footer__list:last-child > .footer__list-item {
    display: none;
  }
  .footer__list:last-child > .footer__social-links {
    display: grid;
    justify-content: center;
  }
  .footer__list:last-child > .footer__social-links span {
    grid-column: 1 / 3;
    margin-bottom: 25px;
  }
  .footer__list:last-child > .footer__social-links .footer__social-link {
    justify-self: center;
    width: 50px;
    height: 50px;
    margin-left: 0;
  }
}

.footer__list-name {
  color: #fff;
  margin-bottom: 10px;
  font-weight: 600;
  display: block;
}

a.footer__link {
  font-size: 14px;
  color: #aadbff;
}

.footer__social-links-mob {
  display: none;
  margin: 10px 0;
}

.footer__social-links {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 8px;
}

.footer__social-link {
  display: inline-block;
  margin-left: 6px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  background-size: contain;
}

.footer__social-link-vk {
  background-image: url(../img/vk.svg);
}

.footer__social-link-fb {
  background-image: url(../img/svg/F_icon.svg);
}

.footer__bottom {
  font-size: 12px;
  text-align: center;
  background-color: #221752;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  padding: 20px;
}

@media only screen and (max-width: 1180px) {
  .footer__bottom {
    grid-gap: 5px;
    flex-direction: column;
  }
}

[class*='-balloon'] {
  box-shadow: none !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  background: rgba(0, 0, 0, 0) !important;
}

[class*='-balloon__layout'] {
  overflow: visible !important;
}

[class*='-balloon__close-button'] {
  background-repeat: no-repeat !important;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/ic_balloon_close.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_balloon_close.webp") 1x);
  background-image: image-set(url("../images/ic_balloon_close.png") 1x);
  /* Safari */
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 1 !important;
}

[class*='-balloon__tail'] {
  left: 145px !important;
  top: -18px;
  z-index: 1;
}

.ballon {
  width: 320px;
  box-sizing: border-box;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.35);
  border: 1px solid #dce2e3;
  border-radius: 25px !important;
  background: #fff;
  margin: 20px;
  padding: 15px;
  text-align: center;
}

.ballon .top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.ballon .rating {
  width: 87px;
}

.ballon .rating .stars {
  width: 100%;
  height: 13px;
}

.ballon .rating .stars span {
  height: 13px;
}

.ballon .location, .ballon .staff {
  color: #000000;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.ballon .location a, .ballon .staff a {
  color: #08b7d7;
}

.ballon .phone {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  font-family: Panton, Arial, sans-serif;
  font-weight: 600;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  padding: 14px 35px 10px;
  margin: 15px auto 0;
}

.banner {
  width: 330px;
  height: 270px;
  box-sizing: border-box;
  padding: 60px 40px 0;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/bg_banner.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/bg_banner.webp") 1x);
  background-image: image-set(url("../images/bg_banner.png") 1x);
  /* Safari */
  background-size: contain;
  color: #fefefe;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.text_block .banner {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1180px) {
  .doctors + .banner.purple {
    margin-top: 30px;
  }
}

.banner.purple {
  width: auto;
  height: auto;
  background-image: url("../images/bg_decor.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/bg_decor.webp") 1x);
  background-image: image-set(url("../images/bg_decor.png") 1x);
  /* Safari */
  background-size: cover;
  background-color: #523fa4;
  padding: 40px;
  box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
  border-radius: 20px;
}

@media only screen and (max-width: 1180px) {
  .banner.purple {
    padding: 20px;
  }
}

.banner.purple .title {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1180px) {
  .banner.purple .title b {
    color: #ffcc00;
  }
}

.banner.purple .info {
  margin-bottom: 20px;
}

.banner .info {
  text-align: left;
  padding-left: 20px;
}

.banner .info .location, .banner .info .metro {
  display: block;
  position: relative;
  color: #fff;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}

.banner .info .location:before, .banner .info .metro:before {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  background-color: initial;
  background-position: 0 0;
  background-image: url("../images/ic_department.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_department.webp") 1x);
  background-image: image-set(url("../images/ic_department.png") 1x);
  /* Safari */
  width: 14px;
  height: 16px;
  top: calc(50% - 4px);
  transform: translateY(-50%);
  left: -24px;
}

.banner .info .metro {
  color: #08b7d7;
}

.banner .info .metro:before {
  background-image: url("../images/ic_metro.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_metro.webp") 1x);
  background-image: image-set(url("../images/ic_metro.png") 1x);
  /* Safari */
  width: 21px;
  height: 15px;
  top: calc(50% - 3px);
  left: -27px;
}

.banner .title {
  font-size: 22px;
  line-height: 28px;
}

.banner .title b {
  font-family: Panton, Arial, sans-serif;
  font-weight: 700;
}

.banner .cta {
  margin-top: 15px;
}

.banner .text {
  display: block;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.banner .phone {
  margin-top: 5px;
  border-radius: 25px;
  background-color: #ffcc00;
  display: inline-block;
  padding: 12px 35px 10px;
  color: #2d256a;
  font-family: Panton, Arial, sans-serif;
  font-weight: 800;
  font-size: 20px;
}

@media only screen and (max-width: 1180px) {
  .banner .phone {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.reviews {
  margin-bottom: 30px;
}

@media only screen and (min-width: 1180px) {
  .info_grid .reviews {
    height: calc(100% + 40px);
    display: flex;
    flex-direction: column;
  }
}

.info_grid .reviews .link {
  margin-top: auto;
  max-width: 240px;
  text-align: center;
}

@media only screen and (max-width: 1180px) {
  .info_grid .reviews .link {
    margin-top: 20px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 1180px) {
  .reviews {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1180px) {
  .reviews .title_block {
    text-align: center;
  }
}

.tabs_content .reviews .title_block {
  text-align: left;
}

@media only screen and (max-width: 1180px) {
  .reviews .slider {
    padding-bottom: 40px;
    margin: 0 -20px;
  }
}

@media only screen and (max-width: 360px) {
  .reviews .slider {
    margin: 0;
  }
}

.reviews .slider .slick-slide {
  overflow: hidden;
}

.reviews .slider .slick-dots {
  position: absolute;
  top: -35px;
  right: 0;
}

@media only screen and (max-width: 1180px) {
  .reviews .slider .slick-dots {
    top: auto;
    bottom: 0px;
    right: 50%;
    transform: translateX(50%);
  }
}

.reviews .slider .slick-dots li {
  float: left;
  margin-right: 7px;
}

.reviews .slider .slick-dots li button {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: transparent;
  background-image: url("../images/ic_dots.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_dots.webp") 1x);
  background-image: image-set(url("../images/ic_dots.png") 1x);
  /* Safari */
  width: 15px;
  height: 14px;
  text-indent: -99999px;
}

.reviews .slider .slick-dots li.slick-active button {
  background-position: -22px;
}

@media only screen and (max-width: 1180px) {
  .reviews .slider .item .review {
    margin: 0 auto;
  }
}

.reviews .review {
  position: relative;
  width: 375px;
  height: 362px;
  box-sizing: border-box;
  padding: 25px;
  background-repeat: no-repeat;
  background-color: initial;
  background-position: 0 0;
  background-image: url("../images/bg_review.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/bg_review.webp") 1x);
  background-image: image-set(url("../images/bg_review.png") 1x);
  /* Safari */
  background-size: contain;
  color: #000;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

@media only screen and (max-width: 360px) {
  .reviews .review {
    width: 100%;
    background-size: cover;
  }
}

.reviews .review a {
  color: #08b7d7;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
}

.reviews .review .name {
  display: block;
  font-family: Panton, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.reviews .review .desc {
  max-height: 180px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.reviews .review .desc p {
  margin-bottom: 5px;
}

.reviews .review .rating {
  position: relative;
  top: -30px;
  left: 25px;
}

.reviews .review .rating .stars {
  margin-bottom: 0;
}

.reviews .review .bottom {
  position: absolute;
  bottom: 11px;
  left: 0;
}

.reviews .review .bottom .author,
.reviews .review .bottom .date {
  padding-left: 63px;
  display: block;
  color: #000;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
}

.reviews .review .bottom .author {
  font-family: Panton, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
}

.reviews .link {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1180px) {
  .reviews .link {
    width: 100%;
    text-align: center;
  }
}

.reviews .grid {
  margin-bottom: 20px;
  flex-direction: column;
}

@media only screen and (max-width: 1180px) {
  .reviews .grid {
    margin: 0 -40px;
  }
}

.reviews .grid .review {
  width: 100%;
  height: auto;
  background: #fff;
  box-shadow: 0 1px 12px #e0e7e7;
  border-radius: 20px;
  padding: 0;
  overflow: hidden;
}

@media only screen and (max-width: 1180px) {
  .reviews .grid .review {
    border-radius: 0;
  }
}

.reviews .grid .review:not(:last-child) {
  margin-bottom: 20px;
}

.reviews .grid .review .top {
  padding: 15px 40px;
}

.reviews .grid .review .bottom {
  padding: 15px 0 10px;
  position: relative;
  bottom: auto;
  background-color: #f5f7ff;
  background-image: url("../images/ic_quotes.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_quotes.webp") 1x);
  background-image: image-set(url("../images/ic_quotes.png") 1x);
  /* Safari */
  background-position: 12px 15px;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1180px) {
  .reviews .grid .review .bottom {
    padding: 15px 40px 10px;
    background-position: 40px 15px;
  }
}

.reviews .grid .review .author,
.reviews .grid .review .date {
  padding-left: 53px;
}

@media only screen and (max-width: 1180px) {
  .reviews .grid .review .date {
    margin-top: 5px;
  }
}

.reviews .grid .review .rating {
  float: right;
  top: auto;
  left: auto;
  margin: 7px 15px 0 0;
}

@media only screen and (max-width: 1180px) {
  .reviews .grid .review .rating {
    position: absolute;
    margin: 0;
    bottom: 15px;
    left: 165px;
  }
}

.review_form {
  box-shadow: 0 1px 12px #e0e7e7;
  border-radius: 20px;
  background-color: #fff;
  padding: 30px 40px;
  margin-bottom: 20px;
  text-align: center;
}

.review_form input,
.review_form textarea {
  width: 100%;
  box-sizing: border-box;
  border-radius: 25px;
  border: 1px solid #cfd9de;
  background-color: #fff;
  padding: 15px 20px;
  color: #9ea3a6;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  margin-bottom: 10px;
}

.review_form textarea {
  margin-bottom: 15px;
}

@media only screen and (max-width: 1180px) {
  .review_form textarea {
    min-height: 150px;
  }
}

.review_form button {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 15px;
}

section.page {
  margin-bottom: 50px;
}

.doctor {
  margin-bottom: 40px;
}

@media only screen and (max-width: 1180px) {
  .bread + .doctor {
    margin-top: -20px;
  }
}

@media only screen and (max-width: 1180px) {
  .doctor .cont {
    padding: 0;
  }
}

.doctor .grid {
  box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
  border-radius: 20px;
  background-color: #fff;
}

@media only screen and (max-width: 1180px) {
  .doctor .grid {
    border-radius: 0;
    padding: 20px 40px;
  }
}

@media only screen and (max-width: 360px) {
  .doctor .grid {
    padding: 20px;
  }
}

.doctor .avatar {
  margin: 30px;
}

@media only screen and (max-width: 1180px) {
  .doctor .avatar {
    margin: 0 20px 0 0;
    float: left;
  }
}

@media only screen and (max-width: 360px) {
  .doctor .avatar {
    margin-right: 10px;
  }
}

.doctor .avatar img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 10px;
  border: 1px solid #edf2f3;
}

@media only screen and (max-width: 1180px) {
  .doctor .avatar img {
    max-width: 115px;
    height: auto;
  }
}

@media only screen and (max-width: 360px) {
  .doctor .avatar img {
    max-width: 80px;
  }
}

.doctor .body {
  padding: 30px 0;
  padding-right: 100px;
}

@media only screen and (max-width: 1180px) {
  .doctor .body {
    padding: 0;
    margin: 0;
  }
}

.doctor .body .top {
  padding: 0 0 20px;
}

@media only screen and (max-width: 1180px) {
  .doctor .body .top:after {
    content: "";
    display: table;
    clear: both;
  }
}

.doctor .body .phone {
  margin-top: 25px;
}

.doctor .body .phone span {
  display: block;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  color: #523fa4;
  font-size: 16px;
  text-align: center;
}

.doctor .body .phone a {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  margin-top: 5px;
}

.doctor .name {
  display: block;
  color: #523fa4;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 25px;
}

@media only screen and (max-width: 1180px) {
  .doctor .name {
    margin-bottom: 25px;
  }
}

.doctor .exp {
  display: inline-block;
  margin-top: 5px;
  border-radius: 13px;
  background-color: rgba(8, 183, 215, 0.2);
  color: #000;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 3px 13px;
}

@media only screen and (max-width: 1180px) {
  .doctor .exp {
    margin: 0 0 10px 0;
  }
}

.doctor .desc {
  color: #333333;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1180px) {
  .doctor .desc {
    font-size: 14px;
  }
}

.doctor .spot {
  margin-bottom: 20px;
}

.doctor .spot a, .doctor .spot span {
  color: #08b7d7;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.doctor .center {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
  background-color: #08b7d7;
  color: #fff;
}

@media only screen and (max-width: 1180px) {
  .doctor .center {
    width: 100%;
    text-align: center;
    background-color: rgba(8, 183, 215, 0.2);
    color: #000;
  }
}

@media only screen and (max-width: 360px) {
  .doctor .center {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.doctor .banner {
  margin: 10px 30px 5px 0;
}

@media only screen and (max-width: 1180px) {
  .doctor .right {
    display: none;
  }
}

.doctors {
  text-align: center;
}

.right .doctors {
  margin-bottom: 35px;
}

.doctors .title_block {
  text-align: left;
}

.doctors .wrap {
  box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
  border-radius: 20px;
  background-color: #fff;
}

.doctors .grid {
  box-shadow: none;
  border-radius: 0;
  background-color: inherit;
  padding: 0;
}

.doctors .slider .slick-dots {
  position: absolute;
  bottom: 20px;
  right: 50%;
  transform: translateX(50%);
}

.doctors .slider .slick-dots li {
  float: left;
  margin-right: 7px;
}

.doctors .slider .slick-dots li button {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/ic_dots.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_dots.webp") 1x);
  background-image: image-set(url("../images/ic_dots.png") 1x);
  /* Safari */
  width: 15px;
  height: 14px;
  text-indent: -99999px;
}

.doctors .slider .slick-dots li.slick-active button {
  background-position: -22px;
}

.doctors .slider .item .outer {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1180px) {
  .doctors .slider .doctor {
    padding: 15px 15px 30px;
  }
}

@media only screen and (max-width: 1180px) {
  .doctors .slider .doctor .center {
    width: 100%;
    box-sizing: border-box;
  }
}

.doctors .doctor {
  width: 100%;
  padding: 30px;
  margin-bottom: 25px;
}

.doctors .doctor .avatar {
  margin: 0 20px 0 0;
}

.doctors .doctor .top {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.doctors .doctor .name {
  display: block;
  color: #08b7d7;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}

.doctors .doctor .exp {
  display: inline-block;
  margin-left: 0;
  margin-bottom: 10px;
}

.doctors .doctor .spot a {
  font-size: 14px;
}

.doctors .doctor .body {
  margin: auto 0 0 0;
  padding: 20px 0 0 0;
  text-align: center;
}

.doctors .doctor .desc {
  color: #000;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
  text-align: left;
}

.doctors .doctor .center {
  font-size: 16px;
  background-color: #08b7d7;
  color: #fff;
}

.doctors .link {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1180px) {
  .doctors .link {
    width: 100%;
    text-align: center;
  }
}

.content .doctors .wrap {
  box-shadow: none;
  border-radius: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 1180px) {
  .content .doctors .wrap {
    flex-direction: column;
  }
}

.content .doctors .doctor {
  width: calc(50% - 10px);
  box-sizing: border-box;
  box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
  border-radius: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1180px) {
  .content .doctors .doctor {
    width: 100%;
  }
}

.content .doctors .doctor .center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0 10px;
}

.content .doctors .doctor .center span {
  color: #fefefe;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 11px;
  margin-right: 5px;
  text-transform: uppercase;
}

@media only screen and (max-width: 1180px) {
  .content .doctors .doctor .center span {
    display: none;
  }
}

.prices {
  margin-bottom: 35px;
}

.prices .list {
  width: 100%;
  box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
}

@media only screen and (max-width: 1180px) {
  .prices .list {
    margin: 0 -40px;
    width: 100vw;
    border-radius: 0;
  }
}

@media only screen and (max-width: 360px) {
  .prices .list {
    margin: 0 -20px;
  }
}

.prices .list thead tr {
  background-color: inherit !important;
}

.prices .list thead th {
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  color: #95a2a4;
  text-transform: uppercase;
  text-align: left;
}

.prices .list thead th:first-child {
  width: 50%;
}

@media only screen and (max-width: 1180px) {
  .prices .list thead th:first-child {
    display: none;
  }
}

@media only screen and (max-width: 1180px) {
  .prices .list thead th:nth-child(2) {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 1180px) {
  .prices .list thead th:nth-child(4) {
    padding-right: 40px;
  }
}

.prices .list tr:nth-child(2n + 1) {
  background-color: #f1f4f5;
}

.prices .list tr.list_name {
  background-color: #08b7d7 !important;
}

.prices .list tr.list_name td {
  color: #fff;
}

@media only screen and (max-width: 1180px) {
  .prices .list tr td:first-child {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 1180px) {
  .prices .list tr td:last-child {
    padding-right: 40px;
  }
}

.prices .list th,
.prices .list td {
  padding: 15px 20px;
  color: #000;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

@media only screen and (max-width: 1180px) {
  .prices .list th,
  .prices .list td {
    font-size: 12px;
    padding: 10px 5px;
  }
}

.prices .list a {
  color: #000;
}

.prices .list a:hover {
  color: #08b7d7;
  text-decoration: none;
}

@media only screen and (max-width: 1180px) {
  .prices .list a:hover {
    color: #fff;
    text-decoration: underline;
  }
}

.prices .list tr.service {
  background-color: #08b7d7;
}

@media only screen and (max-width: 1180px) {
  .prices .list tr.service {
    background-color: #523fa4;
    border-bottom: 1px solid #fff;
  }
  .prices .list tr.service td {
    font-size: 18px;
    line-height: 20px;
  }
}

.prices .list tr.service.active ~ tr {
  display: table-row;
}

.prices .list tr.service.active ~ tr:hover {
  background-color: #f2fbfe !important;
}

@media only screen and (max-width: 1180px) {
  .prices .list tr.service.active ~ tr:hover {
    background: #08b7d7 !important;
  }
}

.prices .list tr.service td {
  color: #fff;
}

.prices .list tr.service ~ tr {
  display: none;
}

.prices .link {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1180px) {
  .prices .link {
    width: 100%;
    text-align: center;
  }
}

/* Можно оптимизировать: удалить верхний код, который отвечает за теги table и сопутствующие ему (код нах-ся во вкладке Инфа о центре)*/
.pricelist__table .collapse {
  display: block;
  width: 13px;
  height: 7px;
  transform: rotate(180deg);
  background-color: initial;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: url("../images/ic_arrow_top.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_arrow_top.webp") 1x);
  background-image: image-set(url("../images/ic_arrow_top.png") 1x);
  /* Safari */
  background-size: contain;
  float: right;
}

.pricelist__spec.active .pricelist__spec-items {
  display: block;
}

.pricelist__spec.active .collapse {
  transform: rotate(0deg);
}

.pricelist__spec-items {
  display: none;
}

.pricelist__table-header,
.pricelist__spec-item,
.pricelist__spec-header {
  display: grid;
  grid-template-columns: 3.5fr 1fr 1.3fr 1.3fr;
}

@media only screen and (max-width: 1180px) {
  .pricelist__spec-item {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.pricelist__spec-header {
  background-color: #08b7d7;
  grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 1180px) {
  .pricelist__spec-header {
    padding: 0 40px;
    background-color: #523fa4;
    border-bottom: 1px solid #fff;
  }
}

.pricelist__spec-item:nth-child(odd) {
  background-color: #f1f4f5;
}

.pricelist__spec-item:hover {
  background-color: #f2fbfe;
}

.pricelist__cell,
.pricelist__th-cell,
.pricelist__spec-name {
  padding: 15px 20px;
  color: #000;
  font-family: OpenSans,Arial,sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.pricelist__spec-name {
  color: #fff;
}

@media only screen and (max-width: 1180px) {
  .pricelist__spec-name {
    font-size: 18px;
    line-height: 20px;
    padding: 15px 0;
  }
}

.pricelist__th-cell {
  font-family: Panton,Arial,sans-serif;
  font-weight: 500;
  color: #95a2a4;
  text-transform: uppercase;
  text-align: left;
}

.pricelist__button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 1180px) {
  .pricelist__cell {
    font-size: 12px;
    padding: 10px 0;
  }
  .pricelist__cell:nth-child(3) {
    padding-left: 40px;
  }
  .pricelist__cell:nth-child(5) {
    padding-right: 40px;
  }
  .pricelist__th-cell {
    font-size: 12px;
    padding: 10px 0;
  }
  .pricelist__th-cell:nth-child(2) {
    padding-left: 40px;
  }
  .pricelist__th-cell:nth-child(4) {
    padding-right: 40px;
  }
  .pricelist__spec-item:nth-child(odd) {
    background: #fff;
  }
  .pricelist__service-name {
    grid-column-start: 1;
    grid-column-end: 4;
    background-color: #08b7d7;
    color: #fff;
    padding: 10px 40px;
  }
  .pricelist__th-cell:first-child {
    display: none;
  }
  .pricelist__table-header {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 360px) {
  .pricelist__cell:nth-child(3) {
    padding-left: 20px;
  }
  .pricelist__cell:nth-child(5) {
    padding-right: 20px;
  }
  .pricelist__th-cell:nth-child(2) {
    padding-left: 20px;
  }
  .pricelist__th-cell:nth-child(4) {
    padding-right: 20px;
  }
  .pricelist__service-name {
    padding: 10px 20px;
  }
  .pricelist__spec-header {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1180px) {
  .bread + .clinic {
    margin-top: -20px;
  }
}

@media only screen and (max-width: 1180px) {
  .clinic .cont {
    padding: 0;
  }
}

.clinic .grid {
  box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
  border-radius: 20px;
  background-color: #fff;
  justify-content: space-between;
  padding: 30px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1180px) {
  .clinic .grid {
    border-radius: 0;
    padding: 30px 40px;
    margin-bottom: 40px;
  }
}

.clinic .grid .col:first-child {
  padding-right: 150px;
}

@media only screen and (max-width: 1180px) {
  .clinic .grid .col:first-child {
    padding: 0;
  }
}

@media only screen and (max-width: 1180px) {
  .clinic .grid .col:last-child {
    display: none;
  }
}

.clinic .name {
  color: #523fa4;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1180px) {
  .clinic .name {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
  }
}

.clinic .info {
  color: #333333;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
}

.clinic .info li {
  padding: 10px 0;
}

.clinic .info li:not(:last-child) {
  border-bottom: 1px solid rgba(237, 242, 243, 0.4);
}

.clinic .info a {
  color: #08b7d7;
}

.clinic .bottom {
  display: flex;
  align-items: center;
}

.clinic .bottom .phone {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
  font-family: Panton, Arial, sans-serif;
  font-weight: 600;
  margin-top: 0;
  margin-right: 30px;
}

@media only screen and (max-width: 1180px) {
  .clinic .bottom .phone {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}

.clinic .bottom .rating {
  display: inline-block;
}

@media only screen and (max-width: 1180px) {
  .clinic .bottom .rating {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .clinic .bottom .rating .stars {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1180px) {
  .clinic .bottom {
    flex-direction: column-reverse;
    align-items: baseline;
  }
}

.tabs {
  margin-bottom: 40px;
}

.tabs .menu {
  margin-bottom: 20px;
  background-color: #08b7d7;
  border-radius: 23px;
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 1180px) {
  .tabs .menu {
    background: none;
    flex-direction: column;
    justify-content: baseline;
    padding: 0 20px;
  }
}

.tabs .menu a {
  width: 100%;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  padding: 15px 0;
  position: relative;
}

.tabs .menu a:not(:last-child):after {
  content: '';
  position: absolute;
  right: 0px;
  top: 5px;
  width: 1px;
  height: calc(100% - 10px);
  background-color: #069eba;
}

@media only screen and (max-width: 1180px) {
  .tabs .menu a:not(:last-child):after {
    display: none;
  }
}

.tabs .menu a.active {
  text-decoration: underline;
}

@media only screen and (max-width: 1180px) {
  .tabs .menu a {
    position: relative;
    color: #08b7d7;
    text-align: left;
    padding: 10px 0;
  }
  .tabs .menu a:before {
    content: '';
    background-color: initial;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: url("../images/ic_arrows.webp");
    /* Firefox */
    background-image: -webkit-image-set(url("../images/ic_arrows.webp") 1x);
    background-image: image-set(url("../images/ic_arrows.png") 1x);
    /* Safari */
    position: absolute;
    top: calc(50% - 6px);
    left: -22px;
    width: 12px;
    height: 12px;
  }
}

.tabs .map {
  position: relative;
  height: 300px;
  box-shadow: 0 1px 12px #e0e7e7;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1180px) {
  .tabs .map {
    height: 400px;
  }
}

.tabs .map .info {
  position: absolute;
  box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
  border-radius: 0 20px 20px 0;
  background-color: #ffffff;
  z-index: 1;
  padding: 13px 50px;
  top: 50%;
  width: 300px;
  transform: translateY(-50%);
  color: #333333;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
}

.tabs .map .info a {
  color: #08b7d7;
}

.tabs .map .info .location,
.tabs .map .info .metro {
  position: relative;
}

.tabs .map .info .location:before,
.tabs .map .info .metro:before {
  content: '';
  position: absolute;
  background-color: initial;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: url("../images/ic_department.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_department.webp") 1x);
  background-image: image-set(url("../images/ic_department.png") 1x);
  /* Safari */
  width: 14px;
  height: 16px;
  top: calc(50% - 4px);
  transform: translateY(-50%);
  left: -30px;
}

.tabs .map .info .metro:before {
  background-image: url("../images/ic_metro.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_metro.webp") 1x);
  background-image: image-set(url("../images/ic_metro.png") 1x);
  /* Safari */
  width: 21px;
  height: 15px;
  top: calc(50% - 3px);
  left: -32px;
}

@media only screen and (max-width: 1180px) {
  .tabs .map .info {
    display: none;
  }
}

.tabs .map #clinic_map {
  height: 100%;
}

.tabs .right .map {
  height: auto;
  position: relative;
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1180px) {
  .tabs .right .map .title_block {
    text-align: center;
  }
}

.tabs .right .map .clinic_map_right {
  width: 100%;
  height: 400px;
  box-shadow: 0 1px 12px #e0e7e7;
  border-radius: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 1180px) {
  .banner.purple + .promotions {
    margin-top: 30px;
  }
}

.right .promotions {
  text-align: center;
  margin-bottom: 35px;
}

.right .promotions .grid {
  box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
  border-radius: 20px;
  background-color: #fff;
  padding: 20px 20px;
}

.right .promotions .item {
  display: block;
  padding-left: 60px;
  border-bottom: none;
  min-height: auto;
  padding-bottom: 0;
}

.right .promotions .item:before {
  width: 50px;
  height: 60px;
}

.promotions .grid {
  flex-direction: column;
}

.promotions .grid .item:not(:last-child) {
  margin-bottom: 20px;
}

.promotions .title_block {
  text-align: left;
}

.promotions .item {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 62px;
  color: #000;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  border-bottom: 1px solid rgba(207, 217, 222, 0.5);
  min-height: 52px;
  padding-bottom: 20px;
}

.promotions .item:last-child {
  border-bottom: none;
}

.promotions .item:before {
  content: '';
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 10px;
  background-color: #523fa4;
  background-image: url("../images/ic_adv_4.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_adv_4.webp") 1x);
  background-image: image-set(url("../images/ic_adv_4.png") 1x);
  /* Safari */
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}

.promotions .item a {
  color: #08b7d7;
}

.promotions .link {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1180px) {
  .promotions .link {
    width: 100%;
    text-align: center;
  }
}

.similar {
  background-color: #08b7d7;
  padding: 50px 0 100px;
  text-align: center;
}

.similar + .search_center {
  margin-top: 40px;
}

.similar .title_block {
  color: #fff;
  margin-bottom: 40px;
}

.similar .slider .slick-dots {
  position: absolute;
  bottom: -36px;
  right: 50%;
  transform: translateX(50%);
}

.similar .slider .slick-dots li {
  float: left;
  margin-right: 7px;
}

.similar .slider .slick-dots li button {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: url("../images/ic_dots.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_dots.webp") 1x);
  background-image: image-set(url("../images/ic_dots.png") 1x);
  /* Safari */
  width: 15px;
  height: 14px;
  text-indent: -99999px;
}

.similar .slider .slick-dots li.slick-active button {
  background-position: -22px;
}

.similar .center {
  background-color: #fff;
  display: flex;
  padding: 20px;
  box-shadow: 1px 3px 9px rgba(110, 100, 170, 0.38);
  border-radius: 20px;
  background-color: #fff;
  margin: 0 20px;
  height: 100%;
  box-sizing: border-box;
}

@media only screen and (max-width: 1180px) {
  .similar .center {
    margin: 0;
  }
}

.similar .center .logo {
  border-radius: 20px;
  border: 1px solid #f1f2f7;
  padding: 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.similar .center .logo img {
  display: block;
  max-width: 60px;
}

.similar .center .body {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.similar .center .body .name {
  display: block;
  margin-bottom: 5px;
  color: #08b7d7;
  font-family: Panton, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.similar .center .body .location {
  color: #333333;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 10px;
}

.similar .center .body .link {
  display: inline-block;
  margin-top: 0;
  border-radius: 15px;
  background-color: #08b7d7;
  padding: 8px 20px;
  color: #fff;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.similar_link {
  margin-top: auto;
}

.mobile_menu {
  left: -70%;
  position: absolute;
  width: 70%;
  height: calc(100vh - 100%);
  overflow-y: scroll;
  background-color: #fafafb;
  transition: left ease-in 200ms;
  z-index: 1;
}

.mobile_menu.is-active {
  left: 0;
}

.mobile_menu .list {
  padding: 10px 0px;
}

.mobile_menu .item {
  padding: 20px 30px;
  border-bottom: 1px solid rgba(207, 217, 222, 0.4);
}

.mobile_menu .item:first-child {
  padding-top: 0;
}

.mobile_menu .item.no_items a:after {
  display: none;
}

.mobile_menu .item .phone {
  text-align: center;
}

.mobile_menu .item .phone span {
  color: #000;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.mobile_menu .item .phone a {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 30px 10px;
  border-radius: 25px;
  background-color: #523fa4;
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
  margin-top: 5px;
  background: none;
  border: 2px solid #08b7d7;
  color: #08b7d7;
  padding: 10px 0;
  width: 100%;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 600;
}

.mobile_menu .item .grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile_menu .item .title {
  color: #000;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  display: block;
  position: relative;
}

.mobile_menu .item .open {
  content: '';
  width: 8px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/ic_arrow_right.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("../images/ic_arrow_right.webp") 1x);
  background-image: image-set(url("../images/ic_arrow_right.png") 1x);
  /* Safari */
}

.mobile_menu .title.back {
  padding-top: 20px;
  display: block;
  text-transform: uppercase;
  color: #523fa4;
  font-family: Panton, Arial, sans-serif;
  font-weight: 800;
}

.mobile_menu .title.back:after {
  transform: rotate(-180deg);
  top: calc(50% - 6px + 10px);
}

.mobile_menu .items {
  display: none;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fafafb;
  overflow-y: scroll;
  z-index: 1;
}

.mobile_menu .items.is-active {
  display: block;
}

.mobile_menu .item .title.open {
  width: 100%;
  height: auto;
}

.tag {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1180px) {
  .tag {
    margin-top: 20px;
  }
}

.tag_items {
  margin: 0 -10px;
}

.tag span {
  color: #333333;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin: 20px 10px;
}

.tag a {
  display: inline-block;
  color: #08b7d7;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin: 10px;
}

.switch {
  display: flex;
  justify-content: space-between;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
}

@media only screen and (max-width: 650px) {
  .switch {
    flex-direction: column;
  }
}

.switch__action-block {
  display: flex;
  border-radius: 50px;
  overflow: hidden;
  padding: 5px;
  background: #523fa4;
}

@media only screen and (max-width: 650px) {
  .switch__action-block:first-child {
    margin-bottom: 5px;
  }
}

.switch__radio-button-label {
  padding: 15px 20px;
  display: block;
  border-radius: 50px;
  transition: 200ms;
  color: #fff;
  user-select: none;
}

.switch__radio-button-label:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.switch__radio-button-label:active {
  transform: scale(0.9);
}

@media only screen and (max-width: 650px) {
  .switch__radio-button-label {
    width: 100%;
    text-align: center;
    padding: 15px 5px;
  }
}

.switch__radio-button-input {
  -webkit-appearance: none;
  margin: 0;
}

.switch__radio-button-input:checked + .switch__radio-button-label {
  color: #2d256a;
  background: #fff;
}

.switch__radio-button-input:checked + .switch__radio-button-label:hover, .switch__radio-button-input:checked + .switch__radio-button-label:active {
  cursor: unset;
  transform: unset;
}

.switch__content {
  display: none;
}

.switch__content.active {
  display: block;
}

.switch__content-link {
  display: block;
  font-family: Panton, Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
}

.mrt-subways,
.kt-subways,
.mrt-area,
.kt-area {
  margin-bottom: 30px;
  column-count: 5;
}

@media only screen and (max-width: 1024px) {
  .mrt-subways,
  .kt-subways,
  .mrt-area,
  .kt-area {
    column-count: 4;
  }
}

@media only screen and (max-width: 768px) {
  .mrt-subways,
  .kt-subways,
  .mrt-area,
  .kt-area {
    column-count: 3;
  }
}

@media only screen and (max-width: 650px) {
  .mrt-subways,
  .kt-subways,
  .mrt-area,
  .kt-area {
    column-count: 2;
  }
}

@media only screen and (max-width: 425px) {
  .mrt-subways,
  .kt-subways,
  .mrt-area,
  .kt-area {
    column-count: 1;
  }
}

.logo__link {
  display: flex;
  align-items: center;
  font-family: Panton, Arial, sans-serif;
  font-weight: 600;
  color: #52469e;
}

.logo__link::before {
  content: '';
  width: 50px;
  height: 50px;
  margin-right: 13px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/images/logo_mini_main.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("/images/logo_mini_main.webp") 1x);
  background-image: image-set(url("/images/logo_mini_main.png") 1x);
  /* Safari */
}

@media only screen and (max-width: 1180px) {
  .logo__link::before {
    width: 33px;
    height: 33px;
    margin-right: 10px;
    background-image: url("/images/logo_mini.webp");
    /* Firefox */
    background-image: -webkit-image-set(url("/images/logo_mini.webp") 1x);
    background-image: image-set(url("/images/logo_mini.png") 1x);
    /* Safari */
  }
}

.logo__link:hover {
  text-decoration: none;
}

@media only screen and (max-width: 1180px) {
  .logo__link {
    color: #fff;
    font-size: 11px;
  }
}

.logo__last-part {
  display: block;
  color: #2fb7d3;
  margin-top: 3px;
}

@media only screen and (max-width: 1180px) {
  .logo__last-part {
    margin-top: 2px;
  }
}

.inner .logo__link {
  color: #fff;
  font-weight: 600;
}

.inner .logo__link::before {
  background-image: url("/images/logo_mini.webp");
  /* Firefox */
  background-image: -webkit-image-set(url("/images/logo_mini.webp") 1x);
  background-image: image-set(url("/images/logo_mini.png") 1x);
  /* Safari */
}

.subway,
.district {
  text-align: center;
  float: left;
  width: 350px;
  margin-right: 20px;
}

img.subway__photo,
img.district__photo {
  max-width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
}

.subway__caption,
.district__caption {
  margin-bottom: 10px;
  color: #9a9a9a;
  text-align: center;
}

@media only screen and (max-width: 450px) {
  .subway,
  .district {
    width: auto;
    margin-right: 0;
  }
}

.booking-online {
  margin-left: 20px;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 1180px) {
  .booking-online {
    margin-left: 0;
  }
}

.booking-online__button {
  color: #fff;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  padding: 12px 25px 10px;
  border-radius: 23px;
  font-size: 18px;
  line-height: 18px;
}

@media only screen and (max-width: 1180px) {
  .booking-online__button {
    border-radius: 5px;
  }
}

.booking-online__submit {
  width: 100%;
}

.booking-online__modal {
  display: none;
  position: absolute;
  background: #f5f7ff;
  padding: 20px;
  border-radius: 20px;
  right: 0;
  top: 50px;
  z-index: 2;
  width: 200px;
  box-shadow: 0 20px 40px -10px #9494ff;
}

.booking-online__input {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-family: inherit;
  min-height: 24px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.cd-accordion {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cd-accordion--animated .cd-accordion__label::before {
  transition: transform .3s, -webkit-transform .3s;
}

.cd-accordion__sub {
  display: none;
  overflow: hidden;
}

.cd-accordion__sub--is-visible {
  display: block;
}

.cd-accordion__item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cd-accordion__input {
  position: absolute;
  opacity: 0;
}

.cd-accordion__label {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75em 1.25em;
  border-bottom: 1px dashed #ffffff;
  color: black;
}

.cd-accordion__label a {
  color: black;
}

.cd-accordion__item:last-of-type > .cd-accordion__label {
  border-bottom: none;
}

.cd-accordion__label span {
  -ms-flex-order: 3;
  order: 3;
}

.cd-accordion__label:hover {
  background: rgba(255, 255, 255, 0.6);
}

.cd-accordion__label--icon-folder::before {
  content: '';
  display: block;
  min-width: 16px;
  min-height: 16px;
  background-image: url("../images/cd-icons.svg");
  background-repeat: no-repeat;
  margin-right: 0.25em;
  filter: brightness(0.7);
}

.cd-accordion__label::after {
  -ms-flex-order: 2;
  order: 2;
}

.cd-accordion__label--icon-folder::before {
  background-position: 0 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.cd-accordion__label--icon-img::after {
  background-position: -48px 0;
}

.cd-accordion__input:checked + .cd-accordion__label::before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.cd-accordion__input:checked + .cd-accordion__label::after {
  background-position: -32px 0;
}

.cd-accordion__input:checked ~ .cd-accordion__sub {
  display: block;
}

.cd-accordion__sub--l1 .cd-accordion__label {
  padding-left: calc(1.25em + 16px);
}

.cd-accordion__sub--l1 .cd-accordion__label:hover {
  filter: brightness(1.1);
}

.cd-accordion__sub--l2 .cd-accordion__label {
  padding-left: calc(1.5em + 32px);
}

.cd-accordion__sub--l3 .cd-accordion__label {
  padding-left: calc(1.5em + 53px);
}

.cd-accordion__active {
  box-shadow: inset -110px 0 50px -50px #cac5ff;
}

.cd-accordion__sub--l1 {
  background: #e8edff;
}

.cd-accordion__sub--l2 {
  background: #d4ddfd;
}

.cd-accordion__sub--l3 {
  background: #cbd6ff;
}

.cd-accordion_no-children::before {
  opacity: 0;
}

.content__h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
}

.content {
  width: 100%;
}

@media only screen and (max-width: 1180px) {
  .content {
    margin-bottom: 40px;
  }
}

.cta {
  font-size: 18px;
}

.cta__header {
  font-size: 37px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #ffffff;
  grid-column: 1 / 4;
  grid-row: 1 / 2;
}

@media only screen and (max-width: 1180px) {
  .cta__header {
    font-size: 1.1em;
    margin-bottom: 15px;
  }
}

.cta__pluses {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1180px) {
  .cta__pluses {
    list-style: disc;
    display: block;
    padding-left: 20px;
    margin-bottom: 10px;
  }
}

.cta__pluses-item {
  font-size: 18px;
  padding: 0 8px;
  border-left: 3px solid;
  margin: 7px 0;
}

@media only screen and (max-width: 1180px) {
  .cta__pluses-item {
    font-size: 0.8em;
    width: auto;
    padding: 0;
    background: none;
    backdrop-filter: none;
    text-align: left;
    display: list-item;
    border-left: none;
  }
}

.cta__action {
  grid-column: 2 / 4;
  grid-row: 2 / 3;
  border-radius: 10px;
  backdrop-filter: saturate(106%) blur(2px);
  display: -ms-flexbox;
  display: flex;
  font-size: 23px;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

@media only screen and (max-width: 1180px) {
  .cta__action {
    font-size: 1em;
    display: block;
    padding: 0;
    background: none;
    text-align: center;
    border-top: 1px dashed;
    margin-top: 25px;
    padding-top: 10px;
    border-radius: 0;
  }
}

.cta__action-text {
  text-align: center;
  background: #3730afcf;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 10px 10px 0 0;
  color: #a68af3;
  font-weight: 500;
}

@media only screen and (max-width: 1180px) {
  .cta__action-text {
    padding-bottom: 10px;
    background: none;
    color: #fff;
  }
}

.cta__action-phone {
  padding: 20px 50px;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 700;
}

@media only screen and (max-width: 1180px) {
  .cta__action-phone {
    font-size: 1.2em;
    display: block;
    text-align: center;
    padding: 15px;
  }
}

.main-block {
  background: #f5f7ff;
  border-radius: 20px;
  padding: 40px;
  margin-bottom: 30px;
}

.main-block .attention {
  background: white;
  border: 2px dashed #d3dcff;
  border-radius: 20px;
  padding: 14px 20px;
}

@media only screen and (max-width: 1180px) {
  .main-block {
    margin: 0 -24px 30px;
  }
  .main-block img {
    width: 100%;
  }
}

.main-block h2, .main-block h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.main-block h2:not(:first-child), .main-block h3:not(:first-child) {
  margin-top: 30px;
}

.main-block h3 {
  font-size: 20px;
}

.main-block p {
  line-height: 24px;
}

.main-block p:not(:last-child) {
  margin-bottom: 15px;
}

.main-block ul {
  list-style: disc;
  padding-left: 20px;
}

.main-block li {
  margin-bottom: 10px;
  line-height: 1.4em;
}

.main-block table {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #16276a;
}

@media only screen and (max-width: 500px) {
  .main-block table {
    font-size: 3vw;
  }
}

.main-block td {
  padding: 10px;
  border: 1px solid #16276a;
}

@media only screen and (max-width: 1180px) {
  .main-block td {
    padding: 5px;
  }
}

.main-block ol {
  list-style-type: decimal;
  list-style-position: outside;
  margin-left: 20px;
}

.main-block:last-of-type {
  margin-bottom: 0;
}

.popular__header {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.popular__body {
  display: flex;
  gap: 20px;
  flex-flow: wrap;
  width: 100%;
}

.popular__item {
  background: #fff;
  padding: 20px;
  white-space: nowrap;
  border-radius: 10px;
  flex-grow: 1;
  text-align: center;
}

@media only screen and (max-width: 1180px) {
  .popular__item {
    white-space: normal;
  }
}

.qna {
  position: relative;
}

.qna__input {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-family: inherit;
  min-height: 24px;
}

@media only screen and (max-width: 1180px) {
  .qna__input {
    box-sizing: border-box;
  }
}

.qna__form {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

@media only screen and (max-width: 1180px) {
  .qna__form {
    flex-direction: column;
  }
}

.qna__submit {
  width: 100%;
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  font-family: inherit;
}

.qna__sub {
  margin-bottom: 20px;
}

.leave-note__form {
  display: grid;
  gap: 0 20px;
  grid: "input input" "button1 button2";
}

@media only screen and (max-width: 1180px) {
  .leave-note__form {
    gap: 10px;
    grid: "input" "button1" "button2";
  }
}

.leave-note__input {
  width: 100%;
  grid-area: input;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1180px) {
  .leave-note__input {
    margin-bottom: 0;
  }
}

.leave-note__submit1 {
  grid-area: button1;
}

.leave-note__submit2 {
  grid-area: button2;
}

.reports {
  width: calc(100vw - 80px);
  max-width: 840px;
}

.discount {
  margin-bottom: 70px;
  /*
      ELEMENT item
  */
}

.discount_items {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -10px -20px;
}

@media only screen and (max-width: 1180px) {
  .discount_items {
    margin: 0 0 -20px;
  }
}

.discount_items .discount_item {
  width: calc(50% - 62px);
  margin: 0 10px 20px;
}

@media only screen and (max-width: 1180px) {
  .discount_items .discount_item {
    width: 100%;
    margin: 0 0 20px;
  }
}

.discount_item {
  background: url("../images/ic_adv_4.png") calc(100% - 20px) 20px/45px no-repeat;
  padding: 20px;
  border: 1px solid #dfe2e5;
  border-radius: 8px;
}

@media only screen and (max-width: 1180px) {
  .discount_item {
    background: none;
    border-radius: 0;
    padding: 0 0 20px;
    border: 0;
    border-bottom: 1px solid #dfe2e5;
  }
}

.discount_item-name {
  display: block;
  margin-bottom: 20px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 600;
  word-break: break-all;
  line-height: 22px;
  font-size: 20px;
  padding-right: 50px;
  color: #2fb7d3;
}

.discount_item-title {
  color: #23282d;
  font-family: Panton, Arial, sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 22px;
}

.discount_item-text {
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 20px;
  font-size: 16px;
}

.discount_item-date {
  background: url("../images/ic_date.png") 0 2px no-repeat;
  color: #8e9499;
  padding-left: 30px;
  line-height: 26px;
}

.pagination {
  margin-top: 25px;
}

.pagination__text {
  display: none;
}

.pagination__item {
  float: left;
  border: 1px solid #dfe2e5;
  overflow: hidden;
}

.pagination__item:not(:last-child) {
  border-right: 0;
}

.pagination__item:first-child {
  border-radius: 6px 0 0 6px;
}

.pagination__item:last-child {
  border-radius: 0 6px 6px 0;
}

.pagination__item.pagination__more {
  display: none;
}

.pagination__link {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  width: 48px;
  height: 48px;
  color: #4f5458;
  font-family: Panton, Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.pagination__link:hover, .pagination__link.is-active {
  background-color: #08b7d7;
  color: #fff;
}

.all-reviews {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -10px -20px;
}

@media only screen and (max-width: 1180px) {
  .all-reviews {
    margin: 0 0 -20px;
  }
}

.all-reviews__item {
  width: calc(50% - 62px);
  margin: 0 10px 20px;
  background: url("../images/ic_quotes.png") calc(100% - 20px) 20px/45px no-repeat;
  padding: 20px;
  border: 1px solid #dfe2e5;
  border-radius: 8px;
}

@media only screen and (max-width: 1180px) {
  .all-reviews__item {
    width: 100%;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 1180px) {
  .all-reviews__item {
    background: none;
    border-radius: 0;
    padding: 0 0 20px;
    border: 0;
    border-bottom: 1px solid #dfe2e5;
  }
}

.all-reviews__name {
  display: block;
  margin-bottom: 20px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 600;
  word-break: break-all;
  line-height: 22px;
  font-size: 20px;
  padding-right: 50px;
  color: #2fb7d3;
}

.all-reviews__stars {
  height: 15px;
  background: url("../images/ic_star_a.png");
  margin-bottom: 15px;
}

.all-reviews__title {
  color: #23282d;
  font-family: Panton, Arial, sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 22px;
}

.all-reviews__text {
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 20px;
  font-size: 16px;
}

.all-reviews__date {
  background: url("../images/ic_date.png") 0 2px no-repeat;
  color: #8e9499;
  padding-left: 30px;
  line-height: 26px;
}

.article-author {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.article-author__avatar span {
  width: 40px;
  height: 40px;
  display: block;
  background-size: cover;
  background-position: 50%;
  border-radius: 50px;
}

.wrap {
  min-height: calc(100% - 308px);
}

.articles-similar {
  margin-top: 50px;
}

.articles-similar__title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
}

.articles-similar__item {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
}

.articles-similar__item-pic,
.articles-similar__item-img {
  width: 100px;
  display: flex;
}

.articles-similar__item-name {
  display: block;
  color: #08b7d7;
  font-size: 16px;
  margin-bottom: 6px;
  font-family: Panton, Arial, sans-serif;
  font-weight: 500;
}

.articles-similar__item-name:hover {
  color: #523fa4;
}

.articles-similar__item-desc {
  display: block;
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.hamburger {
  background: #45348f;
  padding: 8px;
  border-radius: 5px;
}
