header {
    margin-bottom: 30px;

    @include sm {
        position: sticky;
        top: 0;
        z-index: 5;
        margin-bottom: 40px;
        box-shadow: 0 11px 41px 0 #9d87ff;
    }
    
    .top {
        @include clear;
        @include OpenSans(400);
        background-color: $grey_light;
        padding: 8px 0;
        color: $grey_text;
        font-size: 12px;
        a {
            color: $cyan_link;
        }
        .close {
            display: block;
            width: 11px;
            height: 11px;
            background-size: contain;
            background-repeat: no-repeat;
            background-color: initial;
            background-image: url("../images/ic_close.webp"); /* Firefox */
            background-image: -webkit-image-set(
                    url("../images/ic_close.webp") 1x
            );
            background-image: image-set(
                    url("../images/ic_close.png") 1x
            ); /* Safari */
        }

        @include sm {
            font-size: 0px;

            .cont {
                display: flex;
                justify-content: center;
            }
            
            .left, .right {
                float: none;
            }

            span {
                font-size: 12px;
                margin-right: 10px;
            }
        }
    }
}

.header__info {
    @include clear;
    padding: 20px 0;
    display: grid;
    grid: 'logo phone social'/ 1fr auto auto;
    align-items: center;

    @include sm {
        justify-content: space-between;
        padding: 15px 15px 15px;
        margin-bottom: 0;
        grid:
        'menu logo phone'
        'social social social';
    }

    &-phone,
    &-social {
        justify-content: right;

    }



    .cont {
        display: flex;
        justify-content: space-between;
        align-items: center;


    }

    .col {
        display: flex;
        align-items: center;

        @include sm {
        }
    }

    .logo {
        img {
            @include sm {
                max-width: 150px;
            }
        }
    }

    .phone {
        margin-left: 20px;

        &_cta {
            color: $black;
            @include Panton(500);
            font-size: 15px;
            text-transform: uppercase;
            line-height: 1.3;
            top: 3px;

            position: relative;
            margin-left: 50px;
            &:hover {
                color: inherit;
            }
        }

        a {
            @include Panton(500);
            padding: 12px 25px 10px;
            border-radius: 23px;
            color: $white;
            font-size: 18px;
            display: flex;

            @include sm {
                display: block;
                border-radius: 5px;
                border: 2px solid $white;
                padding: 7px;
                font-size: 0;
                background-color: initial;
                background-position: center center;
                background-repeat: no-repeat;
                background-image: url("../images/ic_phone.webp"); /* Firefox */
                background-image: -webkit-image-set(
                                url("../images/ic_phone.webp") 1x
                );
                background-image: image-set(
                                url("../images/ic_phone.png") 1x
                ); /* Safari */
                width: 18px;
                height: 18px;
            }
        }

        @include sm {
            margin-left: 0;
        }

    }

    &.inner {
        background-color: $purple_bg;

        .phone_cta {
            color: $white;
            @include sm {
                display: none;
            }
        }

        .menu a {
            color: $white;
            &:hover {
                color: $cyan_link;
                text-decoration: none;
            }
        }

        .logo {
            .desktop {
                display: none!important;
            }
            .mobile {
                display: block!important;
            }
        }
    }

    @include sm {
        background-color: $purple_bg;
    }
}

.header__info-phone {
    grid-area: phone;
}

.col.header__info-social {
    grid-area: social;

    @include sm {
        display: none;
        background: #38287e;
        border-radius: 10px;
        padding: 10px 10px 10px 15px;
        margin-top: 15px;
    }
}

.col.header__info-social_opened {
    display: flex;
    justify-content: space-between;
}

.header__info-logo {
    grid-area: logo;

    @include sm {
        justify-self: center;
    }
}

.header__info-menu {
    grid-area: menu;
}

.menu.up {
    .menu_item {
        ul {
            bottom: 20px;

            ul {
                bottom: 0;
                top: unset;
            }
        }
    }
}

.menu {
    display: flex;

    a {
        color: $black;
        @include Panton(500);
        font-size: 18px;
        text-transform: uppercase;
        padding: 0 20px;
        &:hover {
            color: $cyan_link;
            text-decoration: none;
        }
    }

    @include sm {
        display: none;
    }

    &_item {
        position: relative;

        &:hover > ul {
            display: block;
        }

        ul {
            display: none;
            position: absolute;
            width: max-content;
            left: -50%;
            padding: 10px 0;
            z-index: 2;

            ul {
                left: 100%;
                top: 0;
                padding-top: 0;
                max-width: 250px;
                max-height: 300px;
                overflow-y: scroll;
            }

            li {
                background-color: #523ba5;
                position: relative;
                padding: 10px 30px;
                &:hover > ul {
                    display: block;
                }
                &:first-child {
                    padding-top: 20px;
                }
                &:last-child {
                    padding-bottom: 20px;
                }
            }

            a {
                color: $white;
                @include Panton(400);
                font-size: 14px;
                text-transform: inherit;
                padding: 0;
            }
        }
    }
}

.header__social-item {
    max-height: 30px;
    max-width: 30px;
    transition: 150ms;

    &:hover {
        transform: scale(1.2);
    }
}

.header__social-img {
    height: 100%;

    @include sm {
        filter: invert(1);
    }
}

.header__social {
    display: flex;
    margin-left: 20px;
    gap: 15px;

    @include sm {
        margin-left: 0;
    }
}

.header__nav,
.header__menu {
    display: flex;
    justify-content: space-between;
}

.header__nav {
    background: $block_bg;
    border-radius: 50px;
    margin-bottom: 20px;
    height: 50px;
    padding: 5px;
    gap: 5px;
    position: relative;

    @include sm {
        background-color: #523fa4;
        display: none;
        padding: 0;
        border-radius: 0;
        height: auto;
        margin-bottom: 0;
    }
}

.header__arrow {
    content: '';
    background-image: url("../images/arrow-up.png");
    width: 120px;
    height: 31px;
    position: absolute;
    bottom: -31px;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    @include sm {
        display: block;
    }
}

.header__arrow_hidden {
    background-image: url("../images/arrow-down.png");
}

.header__menu {
    gap: 5px;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    
    @include sm {
        display: none;
    }
}

.header__menu-item {
    font-weight: 500;
    display: flex;
    line-height: 20px;
    cursor: pointer;
    background: #fff;
    padding: 10px 40px;
    border-radius: 5px;
    width: 100%;
    color: black;
    text-align: center;
    justify-content: center;

    &:first-child {
        border-radius: 20px 5px 5px 20px;
    }

    &:hover {
        text-decoration: none;
        color: #08b7d7;
    }
}

.header__search {
    position: relative;
    width: 400px;

    @include sm {
        width: auto;
        padding: 0 15px 15px;
    }

    &::before {
        content: '';
        background: url('../images/magnifier.png');
        width: 20px;
        height: 20px;
        background-size: contain;
        display: block;
        position: absolute;
        left: 11px;
        top: 0;
        bottom: 0;
        margin: auto;
        opacity: 0.2;
        @include sm {
            left: 30px;
            top: 11px;
            margin: unset;
        }
    }
}

.ya-site-form__input-text {
    height: 25px;
    border-radius: 5px;
    padding: 19px 10px 19px 38px;
    width: 290px!important;
    @include Panton(400);
    border: 5px solid #e8e8f6;

    @include sm {
        box-sizing: border-box;
        width: calc(100vw - 103px)!important;
        padding: 20px 10px 20px 50px;
        border: none;
        border-radius: 10px;
        text-transform: uppercase;
    }
}

#ya-site-form0 .ya-site-form__wrap,
#ya-site-form0 .ya-site-form__search,
#ya-site-form0 .ya-site-form__search-input-layout {
    width: auto!important;
}

#ya-site-form0 .ya-site-form__search-input {
    padding: 0!important;
}

.ya-site-form__submit {
    border-radius: 5px 20px 20px 5px;
    background: #fff;
    @include Panton(400);
    border: none;
    padding: 13px 15px;

    @include sm {
        padding: 14px 15px;
        border-radius: 10px;
    }

    &:hover {
        background: #f9f9f9;
        cursor: pointer;
    }
}

#ya-site-form0 .ya-site-form__submit {
    @include sm {
        margin-left: 10px!important;
    }
}