.clinic {

    .bread + & {
        @include sm {
            margin-top: -20px;
        }
    }

    .cont {
        @include sm {
            padding: 0;
        }
    }
    
    .grid {
        box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
        border-radius: 20px;
        background-color: $white;

        justify-content: space-between;

        padding: 30px;
        margin-bottom: 20px;
        

        @include sm {
            border-radius: 0;
            padding: 30px 40px;
            margin-bottom: 40px;
        }
        
        .col {
            &:first-child {
                padding-right: 150px;
                @include sm {
                    padding: 0;
                }
            }
            &:last-child {
                @include sm {
                    display: none;
                }
            }
        }
    }

    .name {
        color: $purple_bg;
        @include Panton(500);
        font-size: 25px;
        margin-bottom: 20px;

        @include sm {
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 15px;
        }
    }

    .info {
        color: #333333;
        @include Panton(500);
        font-size: 16px;
        line-height: 25px;

        li {
            padding: 10px 0;
            &:not(:last-child) {
                border-bottom: 1px solid rgba($grey_btn_border, 0.4);
            }
        }

        a {
            color: $cyan_link;
        }
    }

    .bottom {
        display: flex;
        align-items: center;

        .phone {
            @include link;
            @include Panton(600);
            margin-top: 0;
            margin-right: 30px;
            @include sm {
                width: 100%;
                text-align: center;
                margin: 0;
            }
        }

        .rating {
            display: inline-block;
            @include sm {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .stars {
                    margin-right: 10px;
                }
            }
        }

        @include sm {
            flex-direction: column-reverse;
            align-items: baseline;
        }
    }


}