.selectize {
    &-input {
        @include button($white);
        border: none;
        padding: 16px 10px 16px 10px;
        white-space: nowrap;

        @include sm {
            padding: 12px 10px;
        }
    
        input, div {
            color: $black;
            @include Panton(400);
            font-size: 16px;
            vertical-align: sub;
            line-height: 16px;
        }

        &.dropdown-active {
            box-shadow: none;
            border-radius: $radius_btn $radius_btn 0 0;
        }


        & > * {
            width: calc(100% - 12px);
            overflow: hidden;
        }

    }
    &-dropdown {
        border:none;
        border-top:1px solid rgba($grey_input_border, .8);

        &-content {
            color: $black;
            @include OpenSans(400);
            font-size: 13px;
            padding-bottom: 10px;
    
            .optgroup-header {
                @include OpenSans(600);
                font-size: 14px;
                line-height: 30px;
                padding-left: 20px;
            }

            .option {
                padding-left: 30px;
                a {
                    color: $cyan_link;
                }
                &.active {
                    background-color: #f1f7f8;
                    color: $black;
                }
            }
        }
    }
}