.filter {
    margin-bottom: 20px;

    @include sm {
        margin-bottom: 15px;
    }

    & + .centers {
        @include sm {
            margin-top: -15px;
        }
    }
    
    .select_line {
        position: relative;
        background-color: $cyan_link;
        display: flex;
        justify-content: center;
        padding: 15px 0;
        margin-bottom: 30px;

        @include sm {
            flex-direction: column;
            padding: 30px 50px;
            margin: 0;

            .metro_or_dep {
            }
        }

        @include smm {
            padding: 30px 20px;
        }

        .selectize {
            &-control {
                margin-right: 21px;

                @include sm {
                    margin: 0 0 10px;
                }

                &.service_or_spec {
                    &:before {
                        content: "";
                        background-repeat: no-repeat;
                        background-position: 0 0;
                        background-color: initial;
                        background-image: url("../images/ic_serv.webp"); /* Firefox */
                        background-image: -webkit-image-set(
                                url("../images/ic_serv.webp") 1x
                        );
                        background-image: image-set(
                                url("../images/ic_serv.png") 1x
                        ); /* Safari */
                        width: 17px;
                        height: 14px;
                        position: absolute;
                        top: 18px;
                        left: 31px;
                        z-index: 2;

                        @include sm {
                            top: 16px;
                        }
                    }
                }
        
                &.metro_or_dep {
                    &:before {
                        content: "";
                        background-repeat: no-repeat;
                        background-position: 0 0;
                        background-color: initial;
                        background-image: url("../images/ic_department.webp"); /* Firefox */
                        background-image: -webkit-image-set(
                                url("../images/ic_department.webp") 1x
                        );
                        background-image: image-set(
                                url("../images/ic_department.png") 1x
                        ); /* Safari */
                        width: 14px;
                        height: 16px;
                        position: absolute;
                        top: 17px;
                        left: 30px;
                        z-index: 2;
                    }
                }
            }
            &-input {
                position: relative;
                width: 420px;
                box-sizing: border-box;
                padding-left: 54px;
                padding-right: 40px;

                @include sm {
                    width: 100%;
                }
            }

            &-dropdown {
                overflow: hidden;
                border-radius: 0 0 25px 25px;
            }
        }

        button[type="submit"] {
            color: $white;
            @include Panton(500);
            font-size: 18px;
            line-height: 24px;
            @include button($purple_bg);
            padding: 12px 91px;
            
            @include sm {
                padding: 12px 15px;
            }
        }

        
    }


    .sort_links {
        @include sm {
            display: none;
        }

        .name {
            color: $black_light;
            @include OpenSans(400);
            font-size: 15px;
        }
        .sort {
            cursor: pointer;
            border-radius: 15px;
            border: 1px solid #f1f2f7;
            background-color: $white;
            padding: 7px 10px;
            color: $grey_text_dark;
            @include OpenSans(400);
            font-size: 14px;
            line-height: 14px;
            margin-left: 10px;

            &:hover {
                text-decoration: none;
            }

            &.active {
                background-color: $cyan_link;
                color: $white;
                &.asc:before, &.desc:before {
                    background-position: -8px 0;
                }
            }

            &.asc, &.desc {
                position: relative;
                padding-left: 20px;

                &:before {
                    content: '';
                    position: absolute;
                    background-repeat: no-repeat;
                    background-color: initial;
                    background-position: 0 0;
                    background-image: url("../images/ic_arrow.webp"); /* Firefox */
                    background-image: -webkit-image-set(
                            url("../images/ic_arrow.webp") 1x
                    );
                    background-image: image-set(
                            url("../images/ic_arrow.png") 1x
                    ); /* Safari */
                    width: 8px;
                    height: 13px;
                    top: 8px;
                    left: 10px;
                    transform: rotate(180deg);
                }
            }

            &.desc {
                &:before {
                    transform: rotate(0deg);
                }
            }
        }
    }
}