.cats_wall {
    margin-bottom: 50px;

    .grid {
        justify-content: space-between;
        flex-direction: column;
    }

    .item {
        width: 100%;

        @include sm {
            width: 100%;
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
        
        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            border-radius: 20px;
            background-color: $cyan_link;

            .title {
                color: $white;
                @include Panton(500);
                font-size: 20px;
                line-height: 24px;
            }

            .link {
                color: $white;
                @include OpenSans(400);
                font-size: 14px;
                line-height: 24px;
                position: relative;
                padding-right: 17px;

                &:after {
                    content: '';
                    background-repeat: no-repeat;
                    background-position: -12px 0;
                    background-color: initial;
                    background-image: url("../images/ic_arrows.webp"); /* Firefox */
                    background-image: -webkit-image-set(
                            url("../images/ic_arrows.webp") 1x
                    );
                    background-image: image-set(
                            url("../images/ic_arrows.png") 1x
                    ); /* Safari */
                    position: absolute;
                    top: 6px;
                    right: 0;
                    width: 12px;
                    height: 12px;
                }
            }
        }

        .body {
            padding: 10px 0;
            box-sizing: border-box;
            
            .list {
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 20px;

                @include sm {
                    flex-direction: column;
                    padding-bottom: 0;
                }
                
                li {
                    width: 33.3%;
                    box-sizing: border-box;
                    position: relative;

                    @include sm {
                        width: 100%;
                        padding: 0!important;
                    }

                    &:nth-child(3n+1) {
                        padding-right: 40px;
                    }

                    &:nth-child(3n+2) {
                        padding-right: 20px;
                        padding-left: 20px;
                    }

                    &:nth-child(3n+3) {
                        padding-left: 40px;
                    }

                    &.active {
                    }
                }

                .name {
                    color: $cyan_link;
                    @include Panton(500);
                    font-size: 16px;
                    line-height: 30px;
                    cursor: pointer;
                }
                
                .sub {
                    padding-bottom: 20px;
                    
                    &_item {
                        display: block;
                        width: 100%;
                        color: $black;
                        @include Panton(400);
                        font-size: 14px;
                        line-height: 16px;
                        position: relative;
                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            bottom: 4px;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            border-bottom: 1px dotted #c0cdcf;
                        }
                    }

                    &_content {
                        display: flex;
                        justify-content: space-between;
                    }



                    .service {
                        background-color: $grey_bg;
                        position: relative;
                        padding-right: 5px;
                        max-width: 60%;
                    }

                    .price {
                        padding-left: 5px;
                        position: relative;
                        background-color: $grey_bg;
                        align-self: flex-end;
                    }

                    .btn_more {
                        display: inline-block;
                        margin-top: 5px;
                        padding: 7px 20px;
                        border-radius: 20px;
                        @include OpenSans(300);
                        border: 1px solid $cyan_link;
                        color: $cyan_link;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}