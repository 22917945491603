.main {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 40px;

    @include sm {
        gap: 0;
        display: block;
    }

    .cont {
        @include sm {
            padding: 0;
        }
    }

    .cta {
        grid-template-columns: 1.4fr 1fr;
        margin-bottom: 30px;
        background-color: initial;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-image: url("../images/bg_cta.webp"); /* Firefox */
        background-image: -webkit-image-set(
                url("../images/bg_cta.webp") 1x
        );
        background-image: image-set(
                url("../images/bg_cta.png") 1x
        ); /* Safari */
        background-size: cover;
        border-radius: 20px;
        display: grid;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        padding: 30px;

        @include sm {
            padding: 30px;
            background-position: right -140px top 0px;
            display: block;
        }
        
        &_title {
            max-width: 500px;
            color: $white;
            @include Panton(600);
            font-size: 39px;
            line-height: 48px;
            margin-bottom: 60px;
            span {
                color: $yellow_text;
                @include Panton(700);
            }

            @include sm {
                font-size: 25px;
                line-height: 30px;
            }
        }
        &_phone {
            display: flex;
            flex-direction: column;

            span {
                color: $white;
                @include Panton(500);
                font-size: 16px;
                line-height: 24px;
            }

            a {
                padding: 24px 70px 16px;
                color: $purple_text;
                @include Panton(700);
                font-size: 30px;
                line-height: 28.06px;
                border-radius: 38px;
                background-color: $green_bg;

                @include sm {
                    font-size: 20px;
                    line-height: 18.83px;
                    padding: 15px 40px;
                }

                @include smm {
                    padding: 15px;
                }
            }
        }
        &_text {
            max-width: 500px;
            margin: 75px 0 0 0;
            color: $white;
            @include Panton(400);
            font-family: Panton;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.main__video {
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    margin-bottom: 30px;

    $padding: 48px;
    $width: calc(100vw - #{$padding});

    iframe {
        width: 100%;
        height: 472px;

        @include sm {
            width: $width;
            height: calc((100vw - #{$padding}) * 9 / 16);
        }
    }
}