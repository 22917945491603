.tag {
  margin-bottom: 20px;

  @include sm {
    margin-top: 20px;
  }

  &_items {
    margin: 0 -10px;
  }

  span {
    color: $black_light;
    @include OpenSans(400);
    font-size: 15px;
    margin: 20px 10px;
  }

  a {
    display: inline-block;
    color: $cyan_link;
    @include OpenSans(400);
    font-size: 15px;
    margin: 10px;
  }
}