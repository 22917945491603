.mobile_menu {
    left: -70%;
    position: absolute;
    width: 70%;
    height: calc(100vh - 100%);
    overflow-y: scroll;
    background-color: $grey_bg;
    transition: left ease-in 200ms;
    z-index: 1;
    
    &.is-active {
        left: 0;
    }

    .list {
        padding: 10px 0px;
    }

    .item {
        padding: 20px 30px;
        border-bottom: 1px solid rgba($grey_input_border, 0.4);

        &:first-child {
            padding-top: 0;
        }

        &.no_items a:after {
            display: none;
        }

        .phone {
            text-align: center;
            span {
                color: $black;
                @include OpenSans(400);
                font-size: 14px;
            }

            a {
                @include link;
                margin-top: 5px;
                background: none;
                border: 2px solid $cyan_link;
                color: $cyan_link;
                padding: 10px 0;
                width: 100%;
                @include OpenSans(600);
            }
        }

        .grid {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .title {
            color: $black;
            @include Panton(500);
            font-size: 16px;
            display: block;
            position: relative;
        }

        .open {
            content: '';
            width: 8px;
            height: 13px;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-color: initial;
            background-image: url("../images/ic_arrow_right.webp"); /* Firefox */
            background-image: -webkit-image-set(
                    url("../images/ic_arrow_right.webp") 1x
            );
            background-image: image-set(
                    url("../images/ic_arrow_right.png") 1x
            ); /* Safari */
        }
    }

    .title.back {
        padding-top: 20px;
        display: block;
        text-transform: uppercase;
        color: $purple_bg;
        @include Panton(800);
        &:after {
            transform: rotate(-180deg);
            top: calc(50% - 6px + 10px);
        }
    }

    .items {
        display: none;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: $grey_bg;
        overflow-y: scroll;
        z-index: 1;
        &.is-active {
            display: block;
        }
    }
}

.mobile_menu .item .title.open {
    width: 100%;
    height: auto;
}