.logo__link {
    display: flex;
    align-items: center;
    @include Panton(600);
    color: #52469e;

    &::before {
        content: '';
        width: 50px;
        height: 50px;
        margin-right: 13px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("/images/logo_mini_main.webp"); /* Firefox */
        background-image: -webkit-image-set(
                url("/images/logo_mini_main.webp") 1x
        );
        background-image: image-set(
                url("/images/logo_mini_main.png") 1x
        ); /* Safari */

        @include sm {
            width: 33px;
            height: 33px;
            margin-right: 10px;
            background-image: url("/images/logo_mini.webp"); /* Firefox */
            background-image: -webkit-image-set(
                    url("/images/logo_mini.webp") 1x
            );
            background-image: image-set(
                    url("/images/logo_mini.png") 1x
            ); /* Safari */
        }
    }

    &:hover {
        text-decoration: none;
    }

    @include sm {
        color: #fff;
        font-size: 11px;
    }
}

.logo__last-part {
    display: block;
    color: #2fb7d3;
    margin-top: 3px;

    @include sm {
        margin-top: 2px;
    }
}

.inner {
    .logo__link {
        color: #fff;
        font-weight: 600;

        &::before {
            background-image: url("/images/logo_mini.webp"); /* Firefox */
            background-image: -webkit-image-set(
                    url("/images/logo_mini.webp") 1x
            );
            background-image: image-set(
                    url("/images/logo_mini.png") 1x
            ); /* Safari */
        }
    }
}
