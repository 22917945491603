html, body {
  height: 100%;
  overflow: auto;
}

body {
  background-color: $white;
  font-family: Panton,Arial,sans-serif;
}

header,
footer,
section {
  @include clear;
}

.title_block {
  color: $black;
  @include Panton(400);
  font-size: 25px;
  margin-bottom: 15px;
  line-height: 35px;

  @include sm {
    font-size: 20px;
    line-height: 25px;
  }
}

.text_block {
  color: $black;
  @include Panton(400);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;

  b {
    line-height: 25px;
    @include Panton(500);
  }

  p {
    margin-bottom: 15px;
  }

  img {
    @include sm {
      width: 100%;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 15px;
    
    li {
      display: flex;
      align-items: top;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
      &:before {
        content: "•";
        color: $cyan_link;
        font-size: 50px;
        margin-right: 5px;
      }
    }
  }

  ol {
    list-style: none;
    margin-bottom: 15px;
    counter-reset: li;
    
    li {
      display: flex;
      align-items: top;
      counter-increment: li;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
      &:before {
        content: counter(li) ".";
        color: $cyan_link;
        margin-right: 5px;
        min-width: 10px;
      }
    }
  }

  @for $i from 1 through 6 {
      h#{7 - $i} {
          font-size: $i * 4.17px; //h1 - 25px
          margin-bottom: 15px;
          @include Panton(400);
      }
  }

  table {
    width: 100%;
    box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
    border-radius: 20px;
    background-color: $white;
    overflow: hidden;
    margin-bottom: 15px;

    @include sm {
      margin: 0 -40px 20px;
      width: calc(100% + 80px);
      border-radius: 0;
    }

    @include smm {
      margin: 0 0 20px;
    }

    thead {
        tr {
            background-color: inherit!important;
        }
        th {
            @include Panton(500);
            color: $grey_table_text;
            text-transform: uppercase;
            text-align: left;
            &:first-child {
                width: 50%;
                @include sm {
                  padding-left: 40px;
                }
            }
            &:last-child {
              @include sm {
                padding-right: 40px;
              }
            }
        }
    }

    tr {
        &:nth-child(2n + 1) {
            background-color: $grey_btn_bg;
        }
        td {
          &:first-child {
              width: 50%;
              @include sm {
                padding-left: 40px;
              }
          }
          &:last-child {
            @include sm {
              padding-right: 40px;
            }
          }
        }
    }

    th, td {
        padding: 15px 20px;
        color: $black;
        @include OpenSans(400);
        font-size: 14px;
        line-height: 12px;
        @include sm {
          padding: 10px;
        }
    }

    a {
        color: $black;

        &:hover {
            color: $cyan_link;
            text-decoration: none;
        }
    }
}
}

.rating {
  text-align: center;
  overflow: hidden;
  a {
    color: $cyan_link;
    @include Panton(400);
    font-size: 13px;
    text-decoration: none;
    &:hover {
      color: $purple_bg;
    }
  }
  .stars {
    display: block;
    width: 105px;
    height: 16px;
    background-color: initial;
    background-image: url("../images/ic_star.webp"); /* Firefox */
    background-image: -webkit-image-set(
            url("../images/ic_star.webp") 1x
    );
    background-image: image-set(
            url("../images/ic_star.png") 1x
    ); /* Safari */
    margin-bottom: 5px;
    span {
      display: block;
      height: 16px;
      background-color: initial;
      background-image: url("../images/ic_star_a.webp"); /* Firefox */
      background-image: -webkit-image-set(
              url("../images/ic_star_a.webp") 1x
      );
      background-image: image-set(
              url("../images/ic_star_a.png") 1x
      ); /* Safari */
    }
  }
  &_user {
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      color: $black;
      @include Panton(400);
      font-size: 16px;
      margin-right: 5px;
      @include sm {
        font-size: 14px;
      }
    }
    .stars {
      display: flex;
      position: relative;
      top: -2px;
      &.hovered {
        .hover {
          background-image: url("../images/ic_star_a.webp"); /* Firefox */
          background-image: -webkit-image-set(
                  url("../images/ic_star_a.webp") 1x
          );
          background-image: image-set(
                  url("../images/ic_star_a.png") 1x
          ); /* Safari */
        }
      }
      &.selected:not(.hovered) {
        .select {
          background-image: url("../images/ic_star_a.webp"); /* Firefox */
          background-image: -webkit-image-set(
                  url("../images/ic_star_a.webp") 1x
          );
          background-image: image-set(
                  url("../images/ic_star_a.png") 1x
          ); /* Safari */
        }
      }
      .star {
        width: 16px;
        height: 15px;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-color: initial;
        background-image: url("../images/ic_star.webp"); /* Firefox */
        background-image: -webkit-image-set(
                url("../images/ic_star.webp") 1x
        );
        background-image: image-set(
                url("../images/ic_star.png") 1x
        ); /* Safari */
        background-size: cover;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;

  @include sm {
    flex-direction: column;
    padding: 0 40px;
  }

  .more {
    @include button($purple_bg);
    padding: 15px 60px;
    color: $white;
    @include Panton(500);
    font-size: 18px;
    line-height: 24px;
    box-sizing: border-box;

    @include sm {
      width: 100%;
      padding: 11px 60px;
      background-color: $cyan_link;
      color: $white;
      margin-bottom: 5px;
    }
  }
  .total {
    margin-left: 20px;
    color: $purple_bg;
    @include Panton(500);
    font-size: 16px;
    line-height: 24px;
  }
  &.pages {
    justify-content: start;
    margin-top: 0;
    flex-direction: row;
    @include sm {
      margin-top: 20px;
      padding: 0;
      justify-content: center;
    }
    .list {
      display: flex;
      justify-content: start;
    }
    a {
      border-radius: 15px;
      border: 1px solid $grey_btn_border;
      background-color: $white;
      color: $black_light_more;
      @include OpenSans(400);
      font-size: 14px;
      line-height: 24px;
      padding: 2px 12px;
      @include sm {
        padding: 2px 8px;
      }
      &:hover {
        text-decoration: none;
        background-color: $cyan_link;
        color: $white;
      }
      &.item {
        border-radius: 0;
        &:not(:first-child) {
          border-left: 0;
        }
        &:first-child {
          border-radius: 15px 0 0 15px;
        }
        &:last-child {
          border-radius: 0 15px 15px 0;
        }
      }
      &.prev {
        margin-right: 10px;
      }
      &.next {
        margin-left: 10px;
      }
    }
  }
}

.bread {
  margin-bottom: 20px;
  position: relative;
  padding: 0 40px;

  @include sm {
    padding: 0 24px;
  }

  a {
    color: $cyan_link;
    @include OpenSans(400);
    font-size: 13px;
    line-height: 24px;
  }
}

.bread__item {
  &:last-child:not(:first-child) a {
    color: $black;
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  }
}

.bread__item:not(:last-child):after {
  content: '';
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: initial;
  background-image: url("../images/ic_breadcrumbs.webp"); /* Firefox */
  background-image: -webkit-image-set(
          url("../images/ic_breadcrumbs.webp") 1x
  );
  background-image: image-set(
          url("../images/ic_breadcrumbs.png") 1x
  ); /* Safari */
  width: 5px;
  height: 10px;
  display: inline-block;
  position: relative;
  margin: 0 5px 0 10px;
  top: 1px;
}

.info {
  &_grid {
    @include ms {
      display: flex;
      justify-content: space-between;
      padding-bottom: 40px;
    }
  }
}

section.search_center {
  margin-bottom: 40px;
  .cont {
    display: flex;
    justify-content: center;
    align-items: center;
    @include sm {
      flex-direction: column;
    }
  }
  .title {
    color: $black;
    @include Panton(400);
    font-size: 25px;
    margin-right: 60px;
    @include sm {
      margin: 0 0 20px;
    }
  }
  .grid {
    @include sm {
      flex-direction: column;
      width: 100%;
    }
    .item {
      justify-content: space-between;
      @include sm {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        margin: 0;
      }
      &:not(:last-child) {
        margin-right: 50px;
        @include sm {
          margin: 0 0 10px;
        }
      }
    }
  }
  .item {
    border-radius: 33px;
    background-color: $grey_btn_bg;
    padding: 25px 60px 21px;
    color: $purple_bg;
    @include Panton(400);
    font-size: 18px;
    @include sm {
      padding: 16px 20px;
      @include Panton(500);
    }
  }
}


.hamburger {

  .line{
    width: 22px;
    height: 3px;
    background-color: $white;
    display: block;

    border-radius: 2px;
    transition: all 0.3s ease-in-out;

    &:nth-child(2) {
      margin: 5px auto;
    }
  }

  &:hover{
    cursor: pointer;
  }

  &.is-active {
    .line {
      &:nth-child(3){
        transform: translateY(-8px) rotate(-45deg);
      }
      &:nth-child(2){
        opacity: 0;
      }
      &:nth-child(1){
        transform: translateY(8px) rotate(45deg);
      }
      
    }
  }
}


.to_top {
  width: 45px;
  height: 45px;
  box-shadow: 0 3px 9px rgba(110, 100, 170, 0.38);
  background-color: $purple_bg;
  border-radius: 50%;
  background-image: url("../images/ic_to_top.webp"); /* Firefox */
  background-image: -webkit-image-set(
          url("../images/ic_to_top.webp") 1x
  );
  background-image: image-set(
          url("../images/ic_to_top.png") 1x
  ); /* Safari */
  background-position: center center;
  background-size: 15px 24px;
  background-repeat: no-repeat;

  .map_open & {
    display: none;
  }
}

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #e9f0f4;
  width: 5px;
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #cad3d9!important;
}

.selectize-dropdown {
  padding: 0px 10px 10px 0;
}

.more_container {
  .more_item {
    display: none;
  }
}

.hidden {
  display: none;
}

.moskva-btn {
  border-radius: 10px;
  background: #6140e5;
  color: #fff700;
  font-weight: 700;
  font-size: 20px;
  font-family: inherit;
  padding: 20px;
  transition: 150ms;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    text-decoration: none;
    color: #fff;
  }
}

.moskva-input {
  box-sizing: border-box;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-family: inherit;
}

.js-feedback {
  position: relative;

  &::before {
    transition: 400ms;
    content: '';
    position: absolute;
    backdrop-filter: saturate(0%) blur(0px);
  }
}

.js-feedback__success {
  &::before {
    text-align: center;
    content: 'Спасибо, скоро мы вам перезвоним';
    position: absolute;
    left: 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
    top: 0;
    width: 100%;
    text-shadow: 0 5px 25px #fff;
    display: flex;
    vertical-align: middle;
    height: 100%;
    background: #ff000000;
    backdrop-filter: saturate(83%) blur(14px);
    justify-content: center;
    align-items: center;
  }

  .moskva-btn:hover {
    transform: none;
  }
}

