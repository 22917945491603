.article-author {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.article-author__avatar span {
  width: 40px;
  height: 40px;
  display: block;
  background-size: cover;
  background-position: 50%;
  border-radius: 50px;
}