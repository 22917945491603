.tabs {
  margin-bottom: 40px;
  .menu {
    margin-bottom: 20px;
    background-color: $cyan_link;
    border-radius: 23px;
    display: flex;
    justify-content: space-around;

    @include sm {
      background: none;
      flex-direction: column;
      justify-content: baseline;
      padding: 0 20px;
    }

    a {
      width: 100%;
      color: $white;
      @include Panton(400);
      font-size: 16px;
      text-align: center;
      padding: 15px 0;
      position: relative;
      &:not(:last-child) {
        &:after {
          content: '';
          position: absolute;
          right: 0px;
          top: 5px;
          width: 1px;
          height: calc(100% - 10px);
          background-color: $cyan_border;
          @include sm {
            display: none;
          }
        }
      }
      &.active {
        text-decoration: underline;
      }
      @include sm {
        position: relative;
        color: $cyan_link;
        text-align: left;
        padding: 10px 0;
        &:before {
          content: '';
          background-color: initial;
          background-repeat: no-repeat;
          background-position: 0 0;
          background-image: url("../images/ic_arrows.webp"); /* Firefox */
          background-image: -webkit-image-set(
                  url("../images/ic_arrows.webp") 1x
          );
          background-image: image-set(
                  url("../images/ic_arrows.png") 1x
          ); /* Safari */
          position: absolute;
          top: calc(50% - 6px);
          left: -22px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .map {
    position: relative;
    height: 300px;
    box-shadow: 0 1px 12px $grey_shadow;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 50px;

    @include sm {
      height: 400px;
    }

    .info {
      position: absolute;
      box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
      border-radius: 0 20px 20px 0;
      background-color: #ffffff;
      z-index: 1;
      padding: 13px 50px;
      top: 50%;
      width: 300px;
      transform: translateY(-50%);
      color: $black_light;
      @include Panton(500);
      font-size: 16px;
      line-height: 25px;
      a {
        color: $cyan_link;
      }
      .location,
      .metro {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          background-color: initial;
          background-repeat: no-repeat;
          background-position: 0 0;
          background-image: url("../images/ic_department.webp"); /* Firefox */
          background-image: -webkit-image-set(
                  url("../images/ic_department.webp") 1x
          );
          background-image: image-set(
                  url("../images/ic_department.png") 1x
          ); /* Safari */
          width: 14px;
          height: 16px;
          top: calc(50% - 4px);
          transform: translateY(-50%);
          left: -30px;
        }
      }
      .metro:before {
        background-image: url("../images/ic_metro.webp"); /* Firefox */
        background-image: -webkit-image-set(
                url("../images/ic_metro.webp") 1x
        );
        background-image: image-set(
                url("../images/ic_metro.png") 1x
        ); /* Safari */
        width: 21px;
        height: 15px;
        top: calc(50% - 3px);
        left: -32px;
      }

      @include sm {
        display: none;
      }
    }
    #clinic_map {
      height: 100%;
    }
  }
  .right {
    .map {
      height: auto;
      position: relative;
      box-shadow: none;
      border-radius: 0;
      margin-bottom: 20px;
      .title_block {
        @include sm {
          text-align: center;
        }
      }
      .clinic_map_right {
        width: 100%;
        height: 400px;
        box-shadow: 0 1px 12px $grey_shadow;
        border-radius: 20px;
        overflow: hidden;
      }
    }
  }
}
