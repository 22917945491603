.promotions {
  .banner.purple+& {
    @include sm {
        margin-top: 30px;
    }
  }
  .right & {
    text-align: center;
    margin-bottom: 35px;
    .grid {
      box-shadow: 1px 3px 24px rgba(110, 100, 170, 0.24);
      border-radius: 20px;
      background-color: $white;
      padding: 20px 20px;
    }
    .item {
      display: block;
      padding-left: 60px;
      border-bottom: none;
      min-height: auto;
      padding-bottom: 0;
      &:before {
        width: 50px;
        height: 60px;
      }
    }
  }
  .grid {
    flex-direction: column;
    .item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .title_block {
    text-align: left;
  }
  .item {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 62px;
    color: $black;
    @include OpenSans(400);
    font-size: 13px;
    line-height: 20px;
    text-align: left;
    border-bottom: 1px solid rgba($grey_input_border, 0.5);
    min-height: 52px;
    padding-bottom: 20px;
    &:last-child {
      border-bottom: none;
    }
    &:before {
      content: '';
      position: absolute;
      width: 52px;
      height: 52px;
      border-radius: 10px;
      background-color: $purple_bg;
      background-image: url("../images/ic_adv_4.webp"); /* Firefox */
      background-image: -webkit-image-set(
              url("../images/ic_adv_4.webp") 1x
      );
      background-image: image-set(
              url("../images/ic_adv_4.png") 1x
      ); /* Safari */
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      left: 0;
      top: 0;
    }
    a {
      color: $cyan_link;
    }
  }
  .link {
    @include link;
    @include sm {
      width: 100%;
      text-align: center;
    }
  }
}
