.content__h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
}

.content {
  width: 100%;

  @include sm {
    margin-bottom: 40px;
  }
}