// font-family 
@mixin font($alias, $name, $weight) {
    @font-face {
        font-family: $alias;
        src: url("../fonts/" + $name + ".woff2") format("woff2"), url("../fonts/" + $name + ".woff") format("woff");
        font-weight: $weight;
        font-style: normal;
        font-display: swap;
    }
}

/*
    Open Sans
*/
@include font(OpenSans, OpenSans-Light, 300);
@include font(OpenSans, OpenSans-Regular, 400);
@include font(OpenSans, OpenSans-SemiBold, 500);
@include font(OpenSans, OpenSans-Bold, 600);
@include font(OpenSans, OpenSans-ExtraBold, 700);

// use @inclide OpenSans
@mixin OpenSans($width) { font-family: OpenSans, Arial, sans-serif; font-weight: $width; }

/*
    Panton
*/
@include font(Panton, Panton-Light, 300);
@include font(Panton, Panton-Regular, 400);
@include font(Panton, Panton-SemiBold, 500);
@include font(Panton, Panton-Bold, 600);
@include font(Panton, Panton-ExtraBold, 700);
@include font(Panton, Panton-Black, 800);

// use @inclide Panton
@mixin Panton($width) { font-family: Panton, Arial, sans-serif; font-weight: $width; }