.wrap {
    position: relative;
}

.cont {
    width: 1180px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;

    @include sm {
        width: 100%;
        padding: 0 24px;
    }

    @include smm {
        padding: 0 20px;
    }
}

.left {
    float: left;
}

.right {
    float: right;
}

.grid {
    display: flex;
}

.desktop {
    @include sm {
        display: none;
    }    
}

.mobile {
    @include ms {
        display: none!important;
    }
}

.map_open {
    overflow: hidden;
}