aside {
    @include sm {
        min-height: auto!important;
    }
    
    #map {
        top: 0;
        position: sticky;
        width: 100%;
        height: 100vh;
        box-sizing: border-box;
        box-shadow: 0 1px 12px $grey_shadow;
        border-radius: 20px;
        overflow: hidden;

        @include sm {
            display: none;
        }

        &.is-active {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: 5;
            border-radius: 0;
        }
    }
}

.aside {
    width: 300px;
    position: sticky;
    top: 40px;
    align-self: flex-start;
    overflow: auto;
    max-height: calc(100vh - 80px);
    margin-bottom: 40px;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #dae2ff;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
        background: #627bd5;
        border-radius: 50px;
    }

    @include sm {
        left: -85vw;
        top: 66px;
        z-index: 10;
        width: 100vw;
        max-height: calc(100vh);
        height: calc(100vh - 66px);
        margin: 0;
        overflow: hidden;
        font-size: 18px;
        position: fixed;
        transition: 200ms;
    }
}

.aside_opened {
    left: 0;
}

.aside_filtered {
    background: #0000008a;
    backdrop-filter: blur(10px);
}

.aside__menu {
    background: $block_bg;
    border-radius: 20px;
    overflow: hidden;

    @include sm {
        border-radius: 0;
        width: 85vw;
        height: calc(100% - 40px);
        padding: 40px 0 0;
        overflow: auto;
        top: 0;
        position: relative;
    }
}

.aside__child-list {
    display: none;
}