.qna {
  position: relative;
}

.qna__input {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-family: inherit;
  min-height: 24px;

  @include sm {
    box-sizing: border-box;
  }
}

.qna__form {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @include sm {
    flex-direction: column;
  }
}

.qna__submit {
  width: 100%;
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  font-family: inherit;
}

.qna__sub {
  margin-bottom: 20px;
}