.subway,
.district {
    text-align: center;
    float: left;
    width: 350px;
    margin-right: 20px;
}

img.subway__photo,
img.district__photo {
    max-width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
}

.subway__caption,
.district__caption {
    margin-bottom: 10px;
    color: #9a9a9a;
    text-align: center;
}

@media only screen and (max-width: 450px) {
    .subway,
    .district {
        width: auto;
        margin-right: 0;
    }
}